body {
    // @include scrollbars(8px, $cor-1, rgb(238, 238, 238));
    // font-feature-settings: 'pnum'on, 'lnum'on;
    // transition: opacity 0.3s ease;
    // letter-spacing: 0.3px;
    // line-height: 140%;
    // background: #fff;
    font-size: 1rem;
    overflow-x: hidden;
    // font-family: $font-1;
    // font-family: "Extatica", sans-serif !important;
    // font-family: $font-1;
    opacity: 0;
 
    font-family: $font-1;
    font-weight: 400;

    // background-image: url('../../src/assets/imagens/background-body.svg');
    
    background-image: url('../../src/assets/imagens/grafismo-body.svg');
    background-repeat: repeat;

    &.dcl {
        opacity: 1;
    }

}
body a{
  font-family: "Extatica", sans-serif ;
  font-family: $font-1;
}

.container {
  margin: 0 auto;
  padding: 0 1.5rem;
  width: 100%;
  position: relative;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1100px;
  }

  @media (min-width: 1400px) {
    max-width: calc(1600px + 3rem);
  }
}


//rem responsivo
html {
    overflow-x: hidden;

    --font-base: 100%;
    font-size: var(--font-base);

    scroll-behavior: smooth;
}

@media (max-width: 1440px) {
    html {
        font-size: calc(var(--font-base) - 9.25%);
    }
}

@media (max-width: 980px) {
    html {
        font-size: calc(var(--font-base) - 12.5%);
    }
}

/*Ajuste Ana*/
/*
.body_home #banner-home.height-video {
  height: calc(100vh - 77px);
}

.menu-space-top {
  margin-top: 77px;
}

@media (max-width:1024px){
body.menu--open #header .header-wrapper{
 top: 0
}

.menu-space-top{
margin-top: 79px;
}

.body_home #banner-home.height-video {
    height: calc(100vh - 79px);
}
}
*/
/*Fim ajuste Ana*/

.menu-space-top{
  margin-top: 0 !important;
}


