 #base-banner .detail-bottom{
    background-color: #F58220;
 }
#categorias{
    background: #FFFFFF;
    /* Neutral/10% OP
    
    0F0F12
    */
    border-bottom: 1px solid rgba(15, 15, 18, 0.1);
    .container{
        overflow: hidden;
    }
 
    // .tabs-wrapper{
    //     // display: flex;
    //     // justify-content: space-between;      
    // }

    .swiper-slide{
        // max-width: max-content;
        flex: 25%;
        min-width: max-content;
        
    }
    .tab{
        // flex-basis: 33%;
      text-align: center;
        span{
            transition: 0.2s ease;
            padding: .4375rem 1.25rem;
            border-radius: 20px;
        }
        &.active{
           
            span{
                background: rgba(214, 223, 33, 0.2);
                
                color: #000000;
               
            }
           
        }

        &:hover{
            span{
                background: rgba(214, 223, 33, 0.2);
                border-radius: 20px;
                color: #000000;
                padding: .4375rem 1.25rem;
            }
           
        }
        p{
            padding: 1.1875rem 1rem;
            display: block;

            font-weight: 700;
            font-size: 1.125rem;
            line-height: 1.4;
            /* identical to box height, or 144% */
            
            // text-align: center;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            cursor: pointer;
            
            /* Neutral/40% OP */
            
            color: rgba(15, 15, 18, 0.4);
        }
    }

} 




#todos-espacos{
    
    margin-bottom: 7.5rem;
    @include d(md2){
        margin-bottom: 4rem;
    }
    // .container{

    //     .espaco:nth-child(even){

    //         @media (min-width: 1024px) {
    //             .grid-espaco .col-2{
    //                 grid-column: 1;
    //                 grid-row: 1;
    
                    
    //             }
    //         }
         
    //     }
    // }
    
    

    .espaco{
        margin-top: 6rem;
        scroll-margin-top: 140px;
        display: none;

        @include d(md2){
            margin-top: 1.5rem;
        }
        .grid-espaco{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1.5rem;
            align-items: flex-start;
            @include d(md){
                grid-template-columns: 1fr;
            }
        }

        .col-1{
            background-color: #F3F3F5;
            align-self: stretch;

            // max-width: calc(528px + 4rem);
            // margin:  0 auto;
            padding: 5rem;

            @include d(lg){
                padding: 3rem;
            }

            @include d(md2){
                padding:  1.5rem ;

            }

            .espaco-title{
                font-style: normal;
                font-weight: 800;
                font-size: 3.5rem;
                line-height: 114%;
                letter-spacing: -0.02em;
                text-transform: uppercase;
                color: #000000;
                margin-bottom: 2rem;

               @include d(md){
                font-size: 2.5rem;
                margin-bottom: 1.5rem;
               }
            }
            .content{
                font-weight: 400;
                font-size: 1.125rem;
                line-height: 144%;
                /* or 144% */
                letter-spacing: -0.02em;

                /* Neutral/70 */

                color: #36363B;
                margin-bottom: 2rem;


                p {
                    margin-bottom: 1rem;
                }
            }


            
            .curadores{
                display: grid;
                width: 100%;

                .swiper-curadores{
                    display: grid;
                    width: 100%;
                }
                .title-curador{
                    font-weight: 700;
                    font-size: 1.125rem;
                    line-height: 144%;
                    /* identical to box height, or 144% */

                    letter-spacing: -0.02em;
                    text-transform: uppercase;

                    /* Neutral/100 */

                    color: #0F0F12;
                    margin-bottom: .75rem;
                }

                .curador-item{
                    // max-width: 150px;

                    .img-wrapper{
                        position: relative;
                        overflow: hidden;
                        padding-top: 100%;

                        img{
                            position: absolute;
                            inset: 0;
                        }
                    }
                    
                    .curador-nome{
                        font-weight: 700;
                        font-size: .875rem;
                        line-height: 150%;
                        margin-top: .5rem;
                       

                        letter-spacing: -0.02em;
                        text-transform: uppercase;

                        /* Primary/Fucsia */

                        color: #A3238E;
                    }
                }
            }
        }

        .col-2{
            display: grid;
            width: 100%;
            .swiper-espaco{
                display: grid;
                width: 100%;

                .swiper-wrapper{
                    // overflow: hidden;
                    position: unset;
                }
                .swiper-slide{
                    height: auto;
                    .titulo-imagem{
                        color:  #FFF;
                        // font-family: "Extatica";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: -0.32px;
                        text-transform: uppercase;
                        position: absolute;
                        bottom: -43px;
                        left: 24px;
                    }
                }
            }
            

            .swiper-controls-custom{
                display: flex;
                align-items: center;
                background-color: #A3238E;
                padding: 8px 12px;
                width: 100%;
                max-width: 787px;

                .pagination{
                    color: #fff;
                }

                

            }

            .swiper-controls-custom-2{
                
                gap: 12px;
                width: 100%;
                text-align: right;
            }


            .espaco-slider{

                .img-wrapper{
                    position: relative;
                    padding-top: 79.86%;
                    overflow: hidden;

                    img{
                        position: absolute;
                        inset: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

            }
            .buttons{
                display: flex;
                gap: 7px;
                align-items: center;

                // position: absolute;
                right: 1rem;

               

                .button{
                    background-color: $bienal-amarelo;
                    width: 1.5rem;
                    height: 1.5rem;
                    border-radius: 50%;
                    display: grid;
                    place-content: center;
                    svg{
                        path{

                            stroke: #000
                        }
                        
                    }
                    &.swiper-button-disabled{
                        background-color: transparent;
                        border: 1px solid white;
                        svg{
                            path{

                                stroke: #fff
                            }
                            
                        }
                    }
                }
                .swiper-button-lock{
                    display: none;
                }
            }
        }
        
    }
    
}

#todos-espacos .espaco.active{
    display: block;
}