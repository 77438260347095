
#visitacao{
    .container-vicitacao{
        max-width: 783px;
        .section-title{
            h2{   
                font-size: 40px;
                font-style: normal;
                font-weight: 800;
                line-height: 56px; /* 140% */
                letter-spacing: -0.8px;
                text-transform: uppercase;
                margin:  96px 0 16px;
            }
            .sub-title{
                color: var(--neutral-70, #36363B);
                font-family: Extatica;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px; /* 144.444% */
                letter-spacing: -0.36px;
                max-width: 475px;
                margin-bottom: 25px;
            }
            
        }

        .margin-top-24 {
            margin-top: 1.5rem;
        }
        
        .conteudo-text{
            display: grid;
            gap: 32px;
            margin-bottom: 24px;
            p{
                color:  #0F0F12;
                font-size: 1.125rem;
                font-style: normal;
                line-height: 133%; /* 133.333% */
                letter-spacing: -0.48px;
            }

            .negrito{
                font-weight:700;
            }
        }
        .button{
    
            @include BotaoMain(#D6DF21);
    
        }
    
        .contact{
            margin-top: 85px;
            h3{
                color: #000;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px; /* 144.444% */
                letter-spacing: -0.36px;
                text-transform: uppercase;
            }

            .contact-container{
                border-radius: 3px;
                border: 1px solid var(--neutral-10-op, rgba(15, 15, 18, 0.10));
                background: var(--white, #FFF);
                padding: 16px;

                h4{
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px; /* 144.444% */
                    letter-spacing: -0.36px;
                    margin-bottom: 8px;
                }
                

                
                .info-wraper{
                    display: flex;
                    gap: 4px;
                    margin-top: 6px;
                    a{
                        color:  #36363B;

                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 26px; /* 144.444% */
                        letter-spacing: -0.36px;
                    }
                }
            }
            .contac-1{
                margin: 16px 0 16px;
                
            }
            .contac-2{
                margin-bottom: 40px;
                
            }

            .visite-mobile{
                width: 100%;
            }
                
        }
    }


    
}

@media(max-width:750px){
    #visitacao{
        .container-vicitacao{
            
            .section-title{
                h2{   
                    font-size: 36px;
                    margin:  64px 0 12px;
                }
                .sub-title{
                    font-size: 16px;
                    margin-bottom: 25px;
                }
                
            }
            
            .conteudo-text{
                p{
                    //font-size: 1rem;
                    letter-spacing: -0.4px;
                }
            }
     
            .contact{
                margin-top: 85px;
                h3{
                    color: #000;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 26px; /* 144.444% */
                    letter-spacing: -0.36px;
                    text-transform: uppercase;
                }
    
                .contact-container{
                    border-radius: 3px;
                    border: 1px solid var(--neutral-10-op, rgba(15, 15, 18, 0.10));
                    background: var(--white, #FFF);
                    padding: 16px;
    
                    h4{
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 26px; /* 144.444% */
                        letter-spacing: -0.36px;
                        margin-bottom: 8px;
                    }
                    
    
                    
                    .info-wraper{
                        display: flex;
                        gap: 4px;
                        margin-top: 6px;
                        a{
                            color:  #36363B;
    
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 26px; /* 144.444% */
                            letter-spacing: -0.36px;
                        }
                    }
                }
                .contac-1{
                    margin: 16px 0 16px;
                    
                }
                .contac-2{
                    margin-bottom: 40px;
                    
                }
            }
        }
    
    
        
    }
}