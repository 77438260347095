/* *
* FC Instagram - Development FCV Team
* Website: https://eplus.dev
* Since: 2019-11
* Version: v2.0.0
* */

/* BEGIN - MEDIUM */
.instagram-wrapper-plugin{
.instagram-gallery-medium {
    // width: 480px;
    // width: 100%;
    justify-content: center;
    // padding: 0 1.75rem;
    gap: 1rem;

    // max-height: 22rem;
    // overflow-y: scroll;

    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
}

.instagram-gallery-medium > a {
    // width: 170px;
    // height: 170px;
    
    cursor: pointer;
    // width: 13rem;
    // height: 13rem;
    
    border-radius: 3px;
    padding-top: 100%;

    position: relative;
    overflow: hidden;
    // margin: 5px;
}

.instagram-gallery-medium > a > video {
    
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.instagram-gallery-medium  video{
    border-radius: 3px;
    position: absolute;
    inset: 0;
    object-fit: cover;
}

  
}
