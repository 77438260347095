

#banner-credenciamento{
    background: #A3238E;
    color: #fff;

    

    .linha-amarela{
        height: 16px;
        background-color: #D6DF21;
    }
    
    .container{
        position: relative;
    }
    .grid-wrappper{
     
        display: grid;
        // grid-template-columns: 1fr 1fr;
        gap: 1rem;
        margin-bottom: 7rem;

        @include d(md2){
            grid-template-columns: 1fr;
        }
    }
    .section-title{
        h2{
            color: #fff;
            max-width: 15ch;
            margin: 0 auto;
            
        }
    }
    .button-wrapper{
    
        // box-shadow: 6px 6px #1d336f !important;
        a{
            @include BotaoMain(#D6DF21, #EF7D00);
    
            box-shadow: 2.5px 2.5px 0px #EF7D00;
    
            // @include BotaoTeste(red, blue);
            // 
            
        }
    }

    .col-2{
            max-width: 514px;
        .text-description{
            font-style: normal;
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 1.4;
            /* or 144% */

            letter-spacing: -0.02em;

            /* White */

            color: #FFFFFF;
            margin-bottom: 2rem;
        }
    }


    .orna-alien{
        position: absolute;
        top: -177px;   
        right: -30px;
        transform: scaleX(-1);
        max-width: 233px;

        @include d(lg){
            max-width: 190px;
        }
        @include d(md){
            top: -150px;
        }
  
    }

    .main-img-wrapper{
        position: relative;
        max-width: max-content;
        // margin-left: 40px;
        position: absolute;    
        left: 0;
        right: 0;
        bottom: 0;
        padding-right: 63px;
        margin:  0 auto;

        @include d(md){
            // width: 120%;
            // margin-left: -13%;]
            display: none;
            
        }

      
        .orna-luneta{
            position: absolute;       
            top: -77px;
            left: 7px;
            @include d(lg){
                // transform: scale(0.5); 
                // max-width: 100px;
                // top: -50px;
            }
            @include d(md){
               
            }
            @include d(md2){
                top: -2px;
            }
        }
        .main-img{
            // width: 100%;
            margin: 0 auto;
            
        }
    }
}

.grid-cards-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    align-items: flex-end;
    position: relative;
    z-index: 3;
    @include d(md){
        grid-template-columns: 1fr;
    }

    .card-item{
        background-color: #A3238E;
        padding: 1.5rem;
        border: 1px solid #FFF;
        max-height: 400px;
        overflow: auto;
       
        .title{
            font-size: 1.8rem;
            text-transform: uppercase;
            margin-bottom: 1rem;
            @include d(lg){
                font-size: 1.5rem;
            }
        }
        .descricao{
            margin-bottom: 1rem;
            p{
                font-size: 1.2rem;
                letter-spacing: -0.44px;
                line-height: 1.4;
                @include d(lg){
                    font-size: 1rem;
                }
            }
        }

        .descricao.profissionais{
            // p{ 
            //     font-size: 1rem;
            //     margin-bottom: 1rem;
            // }
            // strong{
            // font-size: 1.2rem;
            // }
            // ul{
            //     margin-bottom: 1rem;
            // }
        } 
        .button-wrapper{
            transition: 0.2s ease-in;
            &:hover{
                transform: scale(1.02);
               
            }

            a {
                max-width:none !important;
                box-shadow: none !important;
            }

            .button{
                background-color: $bienal-amarelo;
                width: 100%;
                border-radius: 3px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 13px 16px;
                font-weight: 700;
                font-size: .875rem;
                line-height: 1.5;
                /* identical to box height, or 157% */

                letter-spacing: -0.02em;
                text-transform: uppercase;


                /* Black */

                color: #000000;
                @include d(md2){
                    width: 100%;
                    justify-content: center;
                }
            }
            svg{
                width: 24px;
                height: 24px;
                margin-left:1.5rem;
            }
        }
    }
    .card-item:nth-child(1){
        background-color: #DB761D;
        max-width: 425px;
        // margin: 0 auto;
        margin-left: 7px;
        @include d(md){
            margin-left: 0;
            max-width: 490px;
        }
    }
    .card-item:nth-child(2){
        max-width: 409px;
        margin: 0 auto;
        max-height: 500px;
        @include d(md){
            margin-left: 0;
            max-width: 490px;
        }
    }
    .card-item:nth-child(3){
        background: #369AA3;
        max-width: 495px;
        // margin: 0 auto;
        margin-left: auto;
        @include d(md){
            margin-left: 0;
            max-width: 490px;
        }
    }
}



#modal-atracoes.modal-atracoes.credenciamento{

    .gato-modal{
        display: none;
    }
    .modal-guts{
        padding-left: 2rem;
    }
    .descricao{
        padding-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 32px;
        .title-destaque{
            font-size: 1.3rem;
        }
        h2{
            color: #FFF;
            font-size: 24px;
            font-style: normal;
            font-weight: 800;
            line-height: 32px; 
            letter-spacing: -0.48px;
            text-transform: uppercase;
        }
        p{ 
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; /* 144.444% */
            letter-spacing: -0.36px;
        }
        strong{
        font-size: 1.2rem;
        }
        ul{
            margin-bottom: 1rem;
        }


        .button-wrapper{
            // transition: 0.2s ease-in;
            &:hover{
                // transform: scale(1.02);
                // transform-origin: left;
               
            }

            a {
                max-width:none !important;
                box-shadow: none !important;
            }

            .button{
                background-color: $bienal-amarelo;
                width: max-content;
                border-radius: 3px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 13px 16px;
                font-weight: 700;
                font-size: .875rem;
                line-height: 1.5;
                /* identical to box height, or 157% */

                letter-spacing: -0.02em;
                text-transform: uppercase;


                /* Black */

                color: #000000;
                @include d(md2){
                    width: 100%;
                    justify-content: center;
                }
            }
            svg{
                width: 24px;
                height: 24px;
                margin-left:1.5rem;
            }
        }

        .conteudo{
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }
}

#banner-credenciamento .section-title h2{
    font-size: 96px;
    font-style: normal;
    font-weight: 800;
    line-height: 80px; /* 83.333% */
    letter-spacing: -1.92px;
    text-transform: uppercase;
    padding-top: 86px;

    @include d(md){
        font-size: 48px;
        padding-top: 40px;
    }

    
}