// ===================================================

@import "./utilities/responsivo";
@import "./utilities/clamp";
@import "./utilities/utilitarios";


//exemplo de mixin de botao 

@mixin BotaoMain($background:#D6DF21, $color:#000000, $shadow:#A11C64 ){  
    display: flex;
    align-items: center;
    padding: 12px;
    background-color:  $background;
    box-shadow: 2.5px 2.5px $shadow ;
    transition:
    background-color 0.2s ease-in-out,
    transform 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
    will-change: transform; 
    border-radius: 3px;
    max-width: max-content;
   
   
    position: relative;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    will-change: transform;
    span{
       
        color: $color;

        // font-family: "Extatica", sans-serif !important;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 1.6;
        /* identical to box height, or 167% */

        letter-spacing: -0.02em;
        text-transform: uppercase;
        
        /* Black */

        color: #000000;
        -webkit-font-smoothing: subpixel-antialiased;
        font-smoothing: antialiased;
        backface-visibility: hidden;
        transition: transform 0.2s ease;
        will-change: transform;
    }

    &:active,
    &:hover{
        transform: perspective(1px) translate3d(0, 2.5px, 0); 

        box-shadow: 0 0 $shadow ;; 

        span{
            transform: translateZ(0) perspective(1px);
        }
        
    }

    svg,img{
        margin-right: 6px;
        flex-shrink: 0;
        max-width: 30px;
    }
}


@mixin BotaoTeste($cor-texto:white, $cor-background) {
    display: inline-flex;
    align-items: center;
    color: $cor-texto;
    background: $cor-background;
    cursor: pointer;
    text-decoration: none;
    padding: 10px 25px;
    letter-spacing: -0.02em;
    font-size: 1rem;
    font-weight: 500;
    border: 0.99322px solid #1D336F;
    border-radius: 24px;
    font-family: $font-1;
    box-shadow: 2px 2px 0 #1D336F;
    position: relative;
    transition:
    background-color 0.2s ease-in-out,
    transform 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
    will-change: transform; 
   
    img{
        flex-shrink: 0;        
    }

    &:hover {
        // transform: translateY(2px);
        // box-shadow: none;
        // transform: translateY(2px);
        // box-shadow: none;
        // box-shadow: inset 0 0 0 2em red;
       
        //  background-color:rgb(218, 176, 72);
        // box-shadow: 0 0.5em 0.5em -0.4em #ECC150;

        transform: translateY(-3px) scale(1.01);
        background-color: #ECC150;
       
        // box-shadow: 0 6px 4px darkgray
    }
    &:active{
        transform: translateY(2px);
        box-shadow: none;
    }

    &:hover ion-icon {}
}

@mixin Botao($cor-texto: white, $cor-background) {

    //container
    a {
        display: inline-flex;
        align-items: center;
        color: $cor-texto;
        background: $cor-background;
        cursor: pointer;
        font-size: 1.25rem;
        text-decoration: none;

        ion-icon {
            margin-left: 10px;

        }
    }

    //hover
    a {
        &:hover {}

        &:hover ion-icon {}


    }

}







//==================================================


// @mixin botao_example {

//     display: inline-flex;
//     height: 50px;
//     align-items: center;
//     padding: 0 20px;
//     border-radius: 4px;
//     justify-content: space-between;
//     color: white;
//     border: initial;
//     cursor: pointer;
//     background: linear-gradient(89.76deg, #DCBE6D -62.77%, #B68D25 99.8%);
//     box-shadow: 0px 6px 12px rgba(185, 144, 41, 0.15);
//     border-radius: 4px;
//     min-width: 170px;
//     margin: initial;
//     transition: .3s ease;

//     &:hover {
//         box-shadow: 10px 10px 24px rgba(185, 144, 41, 0.25);
//         transform: translateY(-2px) scale(1.04);
//     }
// }


// 🖤  swiper
// @mixin botoes_swiper($bg-direito,) {

//     .swiper-button-next,
//     .swiper-button-prev {
//         background: linear-gradient(89.76deg, #DCBE6D -62.77%, #B68D25 99.8%);
//         box-shadow: 0px 8px 16px 2px rgba(185, 144, 41, 0.1), 0px 16px 32px 2px rgba(185, 144, 41, 0.1);
//         border-radius: 4px;
//         height: 30px;
//         width: 30px;

//         &:after {
//             content: ""; //remove o botao padrao
//         }

//         &.swiper-button-disabled {
//             opacity: 0 !important; //da opacidade 0 quando o botao n ta ativo
//         }

//         @include d(m) { //remove os botoes no mobile e tablet
//             display: none;
//         }
//     }

//     .swiper-button-next {
//         &:before {
//             @include pseudo(absolute); //ja pego o mixin pseudo
//             background-image: ($bg-direito);
//             display: grid;
//             place-items: center;
//             @include size(40%); 
//             @include inset;
//             margin: auto;
//             position: relative;
//             left: 1px;
//         }
//     }

//     .swiper-button-prev {
//         &:before {
//             @include pseudo(absolute);
//             background-image: url($bg-esquerdo;
//             display: grid;
//             place-items: center;
//             @include size(40%);
//             @include inset;
//             margin: auto;

//         }
//     }
// }


// 🤎
// @mixin bullet_swiper($bg) {

//     .swiper-pagination-bullet {
//         background: $($bg);
//         transition: .150s ease-out;

//         transition-property: width,
//             border-radius,
//             scale,
//             opacity;
//         transform-origin: left right;


//         &-active {
//             width: 20px !important;
//             border-radius: 10px !important;

//         }
//     }
// }




//texto 

// @mixin gradiente_no_texto {
//     background: $gradiente-1;
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
// }

// @mixin titulo_das_subpaginas {
//     text-align: center;
//     display: flex;
//     flex-direction: column;
//     align-items: center;

//     h2 {
//         font-family: $font-1;
//         font-weight: 700;
//         font-size: 1.4rem;
//         color: $cor-marrom;
//         display: inline-block;
//         @include center;
//         margin-bottom: 10px;
//         letter-spacing: 1px;
//     }

//     h1 {
//         font-family: $font-1;
//         font-weight: 300;
//         font-size: 2.4rem;
//         margin-bottom: 0;
//         padding-bottom: 0;
//         position: relative;
//         color: $cor-preto-1;

//         @include d(s) {
//             font-size: 2rem;
//         }

//     }

//     h3 {
//         font-family: $font-1;
//         font-weight: 600;
//         font-size: 2rem;
//         margin-top: 10px;
//         color: $cor-preto-1;

//         font-weight: 300;

//         @include d(s) {
//             font-size: 1.5rem;

//         }

//     }


// }



// @mixin card_example() {

//     border-radius: 16px;
//     border: 1px solid rgba(0, 0, 0, 0.05);
//     background: white;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     max-width: 320px;
//     min-width: 320px;
//     padding: 1.25rem;
//     min-height: 400px;

//     @include d(m) {
//         max-width: 20rem;
//         min-width: 20rem;
//     }

//     img {
//         margin-top: 60px;
//         @include size(100px);
//         object-fit: contain;
//     }

//     h2 {
//         color: $cor-preto-1;
//         font-size: 1.5rem;
//         margin-top: 1.25rem;
//         margin-bottom: 1.25rem;
//         font-weight: 700;
//         text-align: center;
//         @include center;
//         display: inline-block;
//     }

//     p {
//         font-weight: 300;
//         text-align: center;
//         @include center;
//         display: inline-block;
//         font-size: 1rem;
//         margin-top: 10px;
//     }
// }