#quem-somos{
    position: relative;
    @include d(md2){
        padding-top: 2rem;
    }
    .alien-balao{
        max-width: 230px;
        transform: rotate(12.93deg);
        // margin-top: 1.875rem;
        position: absolute;
        right: 0px;
        top: 0px;

        @include d(md){
            display: none;
        }
    }
    .livro-rosa-wrapper{
        display: block;
        max-width: 100%;
        position: absolute;
        left: 0px;
        bottom: 15%;
        @media (max-width:1800px){

            

        }
        @media (max-width:1490px){

            

        }
        @media (max-width:1125px){
             display: none;
            

        }
    }
    .vertical-wrapper{
        position: absolute;
        height: 100%;
        left: 1rem;
        max-width: 200px;
        width: 100%;
        top: 0;
        bottom: 0;

        @include d(sm){
            display: none;
        }

        @include d(lg){
            left: -13px;
        }

        .linear{
            
            height: 100%;
            img{
                width: 100%;
                height: 100%;
                max-width: 130px;
                margin: 0 auto;
                object-fit: cover;
            }
        }
        .vertical-items-wrapper{
            padding-top: 4.375rem;
            position: absolute;
            top: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            z-index: 3;


            .alienigena-orna{
                max-width: 105px;
                margin-left: auto;
            }
            .menina-voando{
                margin-left: auto;
            }
            .peixe-wrapper{
                max-width: 140px;
            }
            .menina-livro{
                margin-left: auto;
            }
        }
        // .vertical-item{
        //     position: absolute;
        // }
    }
    .container-quem-somos{
        max-width: calc(1300px + 3rem);
        margin: 0 auto;
    }
    .mao-wrapper{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        @media (max-width:1700px){
            display: none;
        }
        @include d(md){
            display: block;
            top: 140px;
            max-width: 140px;
        }
        @include d(md2){
            top: 100px;
        }
    }
    .header-wrapper{
            display: grid;
            // grid-template-columns: .6fr 2fr;
            align-items: flex-start;
            gap: 1rem;
             margin-bottom: 5.75rem;
             padding-top:  6rem;
            @include d(md){
                grid-template-columns: 1fr;
            }
            @include d(sm){
                padding-top: 3rem;
                
                margin-top: -9px;

            }

            .section-title{
                max-width: 700px;
                margin: 0 auto;
                h2{
                    font-size: 3.2rem !important;
                }
            }
            
            .balao-wrapper{
                display: block;
                max-width: 70px;
                position: absolute;
                left: -163px;
                top: 20%;
                transform: translateY(-50%);
                @media (max-width:1800px){
                    display: none;
                }
            }
            
            .info-evento{
                position: relative;
                // max-width: 648px;

                display: flex;
                align-items: center;
                justify-content: space-around;
                .chaves-detalhe-left{
                    // position: absolute;
                    // left: -184px;
                    // top: -60px;
                    align-self: stretch;
                    flex-shrink: 0;
                    img{
                        height: 100%;
                        object-fit: fill;
                    }
                    @media (max-width:1800px){
                        // display: none;
                    }
                    @include d(md2){
                        display: none;
                    }
                }
                .chaves-detalhe-right{
                    flex-shrink: 0;
                    transform: rotate(-180deg);
                    position: relative;
                    top: 4px;
                    align-self: stretch;

                    img{
                        height: 100%;
                        object-fit: fill;
                    }
                    // position: absolute;
                    // top: -55px;
                    // right: -184px;
                    @media (max-width:1800px){
                        // display: none;
                    }
                    @include d(md2){
                        display: none;
                    }
                }

                .content-for-sobre{
                    font-weight: 400;
                    font-size: 1.125rem;
                    line-height: 1.4;
                    /* or 144% */
            
                    letter-spacing: -0.02em;
                    max-width: 700px;
                    /* Neutral/70 */
            
                    color: #36363B;
                    p{
                        margin-bottom: 1rem;
                    }
                }
               
        
              
            }
            .info-evento-2{
                max-width: 700px;
                width: 100%;
                margin: 0 auto;
                margin-top: -50px;
                @media(max-width:1100px){
                    margin-top:30px;
                }
            }
    }
}

#fotos{
    .swiper.swiper-fotos{
        .swiper-slide{
            // width: fit-content;
            width: max-content;
            height: 392px;
            @include d(md2){
                height: 320px;
            }
            .foto-item{
                height: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

#nossa-historia{
    padding: 10rem 0 5.5rem 0;

    @include d(md2){
        padding: 4rem 0 2.5rem 0;
    }
    .header-wrapper{
        background: url('../../src/assets/imagens/footer/footer-linear.webp');
        background-repeat: no-repeat;
        background-size: cover;
        position: relative; 
        margin-bottom: 4rem;

        padding: 1.5rem 2rem;
        @include d(sm){
            padding: 16px;
        }
        h2{
            color: #fff;
            font-weight: 800;
            font-size: 1.5rem;
            line-height: 1.33;            
            letter-spacing: -0.02em;
            text-transform: uppercase;            
            color: #FFFFFF;

            @include d(sm){
                max-width: 21ch;
            }
        }
        .ornamente-peixe{
            position: absolute;
            right: 10px;
            top: calc(50% - 20px);
            transform: translateY(-50%); 

            @include d(md2){
                max-width: 120px; 
                top: calc(50% - 10px); 
                display: none;
            }
        }
    }



    .swiper-controls-header{

        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;
        @include d(sm){
            margin-top: 1rem;
        }
               
        .pagination{
            font-weight: 500;
            font-size: 16px;
            line-height: 1.5;
            /* identical to box height, or 150% */

            letter-spacing: -0.02em;
            text-transform: uppercase;

            /* Neutral/100 */

            color: #0F0F12;
        }

        .buttons{
            display: flex;
            gap: 4px;
            align-items: center;
            .button{
                background-color: $bienal-amarelo;
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 50%;
                display: grid;
                place-content: center;
                svg{
                    path{

                        stroke: #000
                    }
                    
                }
                &.swiper-button-disabled{
                    // background-color: transparent;
                    // border: 1px solid white;
                    background-color: #E3E3E6;
                    svg{
                        path{

                            stroke: #000
                        }
                        
                    }
                }
            }
        }

    }


    .nossa-historia-wrapper{


        .slide-item-wrapper{
            position: relative;
            padding-top: 86.72%;
            overflow: hidden;

            img{
                position: absolute;
                inset: 0;
                object-fit: cover;

                height: 100%;
                width: 100%;
            }
        }
    }



}