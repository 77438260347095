#base-banner{
    position: relative;
    // margin-top: 129px;

    // @media(max-width:1400px){
    //     margin-top: 123px;
       
    // }
    // @media (max-width:1024px){
    //     // margin-top: 72px;
    //     margin-top: 115px;
    // }


    .banner-img{
        width: 100%;
        object-fit: cover;
    }

    .detail-bottom{
        background: #D6DF21;
        height: 16px;
        margin-top: -16px;
        position: relative;
        z-index: 2;
    }
    // background-image: url('../../src/assets/imagens/blog/background-midia.webp');
    // background-image: url('../../src/assets/imagens/blog/banner-full-blog.webp');
    // background-repeat: repeat;
    // background-size: cover;

    // min-height: 444px;

    // position: relative;

    // margin-top: 160px;
    // display: flex;
    // flex-direction: column;

    // .container{
    //     flex: 1;
    //     height: 100%;
    //     display: grid;
    //     position: relative;
    // }
    // .banner-content{

    //     position: absolute;
    //     top: 50%;
    //     transform: translateY(-50%);
    //     left: 1.5rem;
    //     right: 1.5rem;

    //     h1{
    //         text-align: center;
    //         display: flex;
    //         align-items: center;
    //     }
    // }

    .banner-bottom-info{
        position: absolute;
        //bottom: 0;
        // top: 4.6875rem;
        bottom: unset !important;
        max-height: 69px;
        left: 0;
        right: 0;
        width: 100%;
        background:url('../../src/assets/imagens/junte-se-bg.png') ;
        background-size: cover;
        background-position: center;
        // background:url('../../src/assets/imagens/bg-juntese.png');
        color: #D6DF21;
        z-index: 2;
        overflow: hidden;
        // min-height: 56px;
      
        @include d(sm){
            // top: 77px;
        }
      
      
        // display: grid;
        // grid-template-columns: 1fr max-content 1fr;
        // align-items: center;
      
        display: grid;
      
        text-align: center;
        font-size: 1.375rem;
      
        h1{
            color:  #D6DF21;
            text-align: center;
            // font-family: Extatica;
      
            font-family: 'Extatica-new', sans-serif;
            font-size: 62px;
            font-style: normal;
            font-weight: 700;
            line-height: 77px; /* 110% */
            letter-spacing: -3.84px;
            text-transform: uppercase;
            font-size: 48px;
            max-height: 69px;
            @media (max-width: 1535px){
                font-size: 50px;
            }
            @media (max-width: 1047px){
                font-size: 40px;
                line-height: unset;
            }
            @include d(sm){
      
            }
        }
      
        &.video{
            bottom: 0;
        }
      
        img{
            width: 100%;
            height: 100%;
            // max-height:  56px;
            object-fit: cover;
        }
        @include d(sm){
            font-size: 1rem;
      
            // grid-template-columns: 10px 1fr 10px ;
        }
      
        .linha {
            align-self: stretch;
            img{
                width: 100%;
                height: 100%;
                // max-height:  56px;
                object-fit: cover;
            }
            &.linha-left{
      
                img{
                    object-position: right;
                }
            }
           &.linha-right{
      
                img{
                    object-position: left;
                }
            }
      
      
        }
        p{
      
      
            font-style: normal;
            font-weight: 500;
      
            line-height: 1.4;
            /* identical to box height, or 145% */
      
            letter-spacing: -0.02em;
      
            text-transform: uppercase;
      
            span{
                letter-spacing: -0.02em;
                text-decoration-line: underline;
                font-weight: 800;
            }
        }
      
      
      }
}    

