
#contagem-regressiva{

    background-color: rgba(251, 252, 233, 1);
    padding: 40px 0;

    

    .contagem-wrapper{
        margin: 0 auto;
        max-width: 620px;

        .frase-1{
            color: var(--Primary-Fucsia, #A3238E);
            text-align: center;
            font-family: "Extatica-new";
            font-size: 48px;
            font-style: normal;
            font-weight: 800;
            line-height: 56px; 
            letter-spacing: -0.96px;
            text-transform: uppercase;
            margin-bottom: 24px;
            @media(max-width:800px){
                font-size: 32px;
            }
        }

        .frase-2{
            color: var(--Primary-Azul-noite, #2E3192);
            text-align: center;
            font-family: "Extatica-new";
            font-size: 48px;
            font-style: normal;
            font-weight: 800;
            line-height: 56px; /* 116.667% */
            letter-spacing: -0.96px;
            text-transform: uppercase;
            margin-top: 24px;
            @media(max-width:800px){
                font-size: 32px;

            }
        }

        .cards-contagem{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 24px;
            flex-wrap: wrap;

            .card{
                position: relative;
                // padding: 20px 32px;
                width: 132px;
                height: 132px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;


                img{
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    z-index: 1;
                }
                .conteudo{
                    position: relative;
                    z-index: 2;
                }

                .dias, .horas, .min, .seg{
                    color:  #D6DF21;
                    font-family: "Extatica-new";
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 56px; /* 116.667% */
                    letter-spacing: -0.96px;
                    text-transform: uppercase;
                    text-align: center;
                }
                
                .text{
                    color: #FFF;
                    text-align: center;
                    font-family: "Extatica-new";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 32px; /* 133.333% */
                    letter-spacing: -0.48px;
                    text-transform: uppercase;
                }
            }
        }
    }

    .cards-contagem {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;
        flex-wrap: wrap;
    }
    
    .card {
        position: relative;
        width: 132px;
        height: 132px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        perspective: 500px; /* Adiciona efeito 3D */
    }
    
    .card img {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;
        transition: transform 0.5s ease-in-out;
    }
    
    .card.flip img {
        transform: rotateX(90deg);
    }
    
    .conteudo {
        position: relative;
        z-index: 2;
        overflow: hidden;
        height: 56px;
        display: flex;
        flex-direction: column;
        align-items: center;
        perspective: 500px; /* Profundidade 3D */
        height: 100%;
        justify-content: center;
    }
    
    .dias, .horas, .min, .seg {
        color: #D6DF21;
        font-family: "Extatica-new";
        font-size: 48px;
        font-weight: 800;
        text-transform: uppercase;
        text-align: center;
        position: relative;
        overflow: hidden;
        height: 56px;
    }
    
    .dias p, .horas p, .min p, .seg p {
        margin: 0;
        display: inline-block;
        transform-origin: center;
        transition: transform 0.2s ease-in-out;
    }
    
    .flip p {
        transform: rotateX(90deg);
    }
    
}

#contagem-regressiva-2{

    background-color: rgba(251, 252, 233, 1);
    padding: 28px 0;
    position: fixed;
    bottom: -300px;
    z-index: 10;
    width: 100%;
    transition: bottom 0.4s ease-in-out;

    @media(max-width:800px){
        padding: 40px 0;
    }

    .contagem-container{
        position: relative;

        .botao-x{
            position: absolute;
            right: 40px;
            bottom: 30%;
            top: 30%;
            z-index: 11;
        }
    }

    .contagem-wrapper{
        margin: 0 auto;
        max-width: 620px;

        .cards-contagem{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 24px;
            flex-wrap: wrap;

            .card{
                position: relative;
                // padding: 20px 32px;
                width: 132px;
                height: 132px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 76px;
                height: 76px;

                img{
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    z-index: 1;
                }
                .conteudo{
                    position: relative;
                    z-index: 2;
                }

                .dias, .horas, .min, .seg{
                    color:  #D6DF21;
                    font-family: "Extatica-new";
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;              
                    letter-spacing: -0.96px;
                    text-transform: uppercase;
                    text-align: center;

                    @media(max-width:650px){
                        font-size: 20px;
                    }
                }
                
                .text{
                    color: #FFF;
                    text-align: center;
                    font-family: "Extatica-new";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    letter-spacing: -0.48px;
                    text-transform: uppercase;
                }
            }
        }
    }

    .cards-contagem {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;
        flex-wrap: wrap;
    }
    
    .card {
        position: relative;
        width: 132px;
        height: 132px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        perspective: 500px; /* Adiciona efeito 3D */
    }
    
    .card img {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;
        transition: transform 0.5s ease-in-out;
    }
    
    .card.flip img {
        transform: rotateX(90deg);
    }
    
    .conteudo {
        position: relative;
        z-index: 2;
        overflow: hidden;
        height: 56px;
        display: flex;
        flex-direction: column;
        align-items: center;
        perspective: 500px; /* Profundidade 3D */
        height: 100%;
        justify-content: center;
    }
    
    .dias, .horas, .min, .seg {
        color: #D6DF21;
        font-family: "Extatica-new";
        font-size: 48px;
        font-weight: 800;
        text-transform: uppercase;
        text-align: center;
        position: relative;
        overflow: hidden;
        height: 56px;
    }
    
    .dias p, .horas p, .min p, .seg p {
        margin: 0;
        display: inline-block;
        transform-origin: center;
        transition: transform 0.2s ease-in-out;
    }
    
    .flip p {
        transform: rotateX(90deg);
    }
    
}

#contagem-regressiva-2.active-bottom {
    bottom: 0;
}


#contagem-regressiva-2.remova-bottom {
    bottom: -300px !important;
}

@media(max-width:650px){

    #contagem-regressiva-2{
        padding: 16px 0;
    }

    #contagem-regressiva-2 .contagem-wrapper{
        display: flex;
    }

    #contagem-regressiva-2 .contagem-wrapper .cards-contagem{
        gap: 8px;
    }

    #contagem-regressiva-2 .contagem-wrapper .cards-contagem .card{
        width: 50px;
        height: 50px;
    }
    

    #contagem-regressiva-2 .contagem-wrapper .cards-contagem .card .dias, #contagem-regressiva-2 .contagem-wrapper .cards-contagem .card .horas, #contagem-regressiva-2 .contagem-wrapper .cards-contagem .card .min, #contagem-regressiva-2 .contagem-wrapper .cards-contagem .card .seg {
        font-size: 20px;
    }

    

    #contagem-regressiva-2 .contagem-wrapper .cards-contagem .card .text{
        font-size: 14px;
    }

    .contagem-container{
        position: relative;

        .botao-x{
            position: absolute;
            right: 40px;
            bottom: unset !important;
            top: unset !important;
        }
    }

}