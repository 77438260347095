
#banner-home{
    position: relative;

    &.height-video{
        height: calc(120vh - 123px);

        @media(max-width:1400px){

            height: calc(120vh - 123px);
        }
        @media (max-width:1024px){

            height: calc(120vh - 113px);
        }
    }


    &::after{
        content: '';
        display: none;
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.5);
        mix-blend-mode: multiply;

        @include d(md2){
            // display: none;
        }
    }

    //  @media(max-width:1400px){

    //      height: calc(100vh - 123px);
    //  }
    //  @media (max-width:1024px){

    //      height: calc(100vh - 113px);
    //  }

    .video-wrapper{
        position: absolute;
        inset: 0;
        bottom: 55px;
        overflow: hidden;

        .mobile-banner-img{
            display: none;
            height: 100%;
            @include d(md2){
                display: block;

            }
            img{
                width: 100%;
                object-fit: cover;
                height: 100%;
            }

        }

        #video_mobile{
            @include d(md2){
                display: none;
            }
        }

        video{
            height: 100%;
            width: 100%;
            object-fit: cover;

        }
    }

    .container{
        height: 100%;
    }

    .banner-content{
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;
        z-index: 3;

        span{

            font-style: normal;
            font-weight: 400;
            font-size: 1.5rem;

            @media (max-width: 1440px){
                font-size: 1.3rem;
            }
            line-height: 1.33;
            /* identical to box height, or 133% */

            text-align: center;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            margin-bottom: 1rem;
            display: block;
            /* White */

            color: #FFFFFF;

            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);

            @media (max-width: 1440px){
                font-size: 1.3rem;
            }
            @include d(md2){
                font-size: 1.1rem;
            }

        }

        h1{
            font-weight: 700;
            font-size: 8rem;
            line-height: 1.1;



            // text-align: center;
            letter-spacing: -0.03em;
            text-transform: uppercase;

            /* White */

            color: #FFFFFF;

            text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);

            @media (max-width: 1440px){
                font-size: 6rem;
            }
            @include d(md2){
                font-size: 4rem;

            }
            @include d(sm){
                max-width: 15ch;
                margin: 0 auto;
            }


        }
        .button-wrapper-banner{
            max-width: max-content;
            margin: 0 auto;
            margin-top: 2rem;

            a{
                @include BotaoMain(#A3238E);
                box-shadow: 2.5px 2.5px #D6DF21;
                span{
                    margin-bottom: 0;
                    color: #fff !important;
                    font-size: 1.125rem;
                    font-weight: 500;
                }

                svg{
                    width: 32px;
                    height: 32px;
                    filter: brightness(0) invert(1);
                }
            }
        }
    }

    .banner-bottom-info{
        position: absolute;
        //bottom: 0;
        // top: 4.6875rem;
        bottom: unset !important;
        max-height: 69px;
        left: 0;
        right: 0;
        width: 100%;
        background:url('../../src/assets/imagens/junte-se-bg.png') ;
        background-size: cover;
        background-position: center;
        // background:url('../../src/assets/imagens/bg-juntese.png');
        color: #D6DF21;
        z-index: 2;
        overflow: hidden;
        // min-height: 56px;

        @include d(sm){
            // top: 77px;
        }


        // display: grid;
        // grid-template-columns: 1fr max-content 1fr;
        // align-items: center;

        display: grid;

        text-align: center;
        font-size: 1.375rem;

        h1{
            color:  #D6DF21;
            text-align: center;
            // font-family: Extatica;

            font-family: 'Extatica-new', sans-serif;
            font-size: 62px;
            font-style: normal;
            font-weight: 700;
            line-height: 77px; /* 110% */
            letter-spacing: -3.84px;
            text-transform: uppercase;
            font-size: 48px;
            max-height: 69px;
            @media (max-width: 1535px){
                font-size: 50px;
            }
            @media (max-width: 1047px){
                font-size: 40px;
                line-height: unset;
            }
            @include d(sm){

            }
        }

        &.video{
            bottom: 0;
        }

        img{
            width: 100%;
            height: 100%;
            // max-height:  56px;
            object-fit: cover;
        }
        @include d(sm){
            font-size: 1rem;

            // grid-template-columns: 10px 1fr 10px ;
        }

        .linha {
            align-self: stretch;
            img{
                width: 100%;
                height: 100%;
                // max-height:  56px;
                object-fit: cover;
            }
            &.linha-left{

                img{
                    object-position: right;
                }
            }
           &.linha-right{

                img{
                    object-position: left;
                }
            }


        }
        p{


            font-style: normal;
            font-weight: 500;

            line-height: 1.4;
            /* identical to box height, or 145% */

            letter-spacing: -0.02em;

            text-transform: uppercase;

            span{
                letter-spacing: -0.02em;
                text-decoration-line: underline;
                font-weight: 800;
            }
        }


    }

    .swiper-pagination-bullet{
        background: #FFFFFF;
        border-radius: 6px;
        width: 24px;
        height: 12px;
    }

    .banner-image{
        width: 100%;
        min-height: 300px;
        object-fit: cover;
    }

   .conteudo-wrapper {
        position: absolute;
        top: 34%;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        //max-width: 71.75rem;
        max-width: 100vw;
        width: 100%;

        @include d(md){
            top:26%;
        }

        @include d(md2){
            top: 35%;
            text-align: center;
            padding: 0 1rem;
        }

        @media(max-width:500px){
            top: 27%;
        }

        h1 {
            font-weight: 700;
            font-size: 5.5rem;
            line-height: 1.1;
            letter-spacing: -0.03em;
            text-transform: uppercase;
            color: #FFFFFF;
            text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
            margin-bottom: 2rem;
            max-width: 68.75rem;
            text-align: center;

            @media (max-width:1750px){
                font-size: 4.5rem;
            }

            @media (max-width:1450px){
                font-size: 3.5rem;
                max-width: 54.75rem;
            }

            @include d(md){
                width: 37rem;
                font-size: 3rem;
            }

            @include d(md2){
                width: 23rem;
                font-size: 3rem;
            }

        }

        h3 {
            margin-bottom: 1rem;
            color: var(--white, #FFF);
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: 133%; /* 133.333% */
            letter-spacing: -0.03rem;
            text-transform: uppercase;

            @include d(md){
                font-size: 1.25rem;
            }
        }

        h2 {
            margin-top: .5rem;
            margin-bottom: 2rem;
            color: #FFF;
            font-size: 2.75rem;
            font-weight: 600;
            line-height: 4rem;
            letter-spacing: -0.055rem;
            text-transform: uppercase;

            @media (max-width:1450px){
                font-size: 2rem;
            }

            @include d(md){
                font-size: 1.25rem;
            }
        }

        .btn{
            a{
                    @include BotaoMain(#A3238E);
                    box-shadow: 2.5px 2.5px #D6DF21;
                    span{
                        margin-bottom: 0;
                        color: #fff !important;
                        font-size: 1.125rem;
                        font-weight: 500;
                    }

                    svg{
                        width: 32px;
                        height: 32px;
                        filter: brightness(0) invert(1);
                    }
                }
        }
    }
}

#banner-ao-vivo {
    position: relative;

    .bg-image-ao-vivo{
        min-height: 200px;
        width: 100%;

        @include d(md){
            object-fit: cover;
        }
    }

    .text-content{
        max-width: 722px;
        width: 100%;
        position: absolute;
        top: 3.85rem;
        left: 24rem;

        @media (max-width:1600px){
            top: 3rem;
            left: 22rem;
        }

        @media (max-width:1400px){
            max-width: 275px;
            top: 4rem;
            left: 18rem;
        }

        @media (max-width:1200px){
            top: 2.75rem;
            left: 16rem;
        }

        @include d(md){
            left: 11rem;
        }

        @include d(md2){
            top:6rem;
            left: 7rem;
            max-width: 180px;
        }

        @media (max-width:500px){
            top: 4rem;
        }

        @media (max-width:450px){
            top: 3rem;
            left: 6rem;
        }

        @media (max-width:400px){
            top: 2rem;
            left: 4rem;
        }

         @media (max-width:350px){
            left: 2.5rem;
        }

        h1{
            font-size: 4.5rem;
            font-style: normal;
            font-weight: 800;
            line-height: 111%; /* 111.111% */
            letter-spacing: -0.09rem;
            text-transform: uppercase;
            color:var(--primary-azul-noite, #2E3192);
            margin-bottom: 1rem;

             @media (max-width:1400px){
                font-size: 2rem;
            }

            @include d(md){
                left: 14rem;
            }

            // @media (max-width:400px){
            //     font-size: 1.5rem;
            // }
        }

        p{
            color: var(--primary-azul-noite, #2E3192);
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: 133%; /* 133.333% */
            letter-spacing: -0.03rem;
            margin-bottom: 2rem;

            @media (max-width:1400px){
                font-size: 1rem;
            }

            @media (max-width:1200px){
                margin-bottom: 1rem;
            }

            @include d(md2){
                margin-bottom: 2rem;
            }
        }

        .button-live{
            max-width: 12.65rem;
            display: flex;
            padding: .75rem 1rem;
            align-items: center;
            gap: 1.5rem;
            background: var(--bienal-amarelo);
            border-radius: .1875rem;
            color: var(--black);
            font-size: .875rem;
            font-style: normal;
            font-weight: 700;
            line-height: 157%; /* 157.143% */
            letter-spacing: -0.0175rem;
            text-transform: uppercase;
            box-shadow: 2.5px 2.5px #a11c64;

            &:hover{
                transform: perspective(1px) translate3d(0, 2.5px, 0);
            }

            svg{
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }
}




#o-evento{
    position: relative;
    padding-top: 13rem;
    .section-title .section-description{
        text-align: justify;
    }
    .grid-wrapper{
        display: grid;
        grid-template-columns: 0.8fr 1.2fr;
        align-items: center;

        @include d(md){
            grid-template-columns: 1fr;
            gap: 2rem ;
        }

    }
    .icon-laranja{
        position: absolute;
        right: 0;
        top: 4rem
    }
    .badge-for-ornamento{
        position: relative;
        width: max-content;
        .icon-luneta{
            position: absolute;
            z-index: 2;
            top: -60px;
            right: 0;
        }
    }
    .col-2{
        position: relative;

        @include d(md2){
            margin-top: 2rem;
        }
    }

    .card-flutuante {
        background: #FFFFFF;

        border: 1px solid rgba(15, 15, 18, 0.05);
        box-shadow: 0px 7.93959px 31.7584px rgba(53, 55, 56, 0.08);
        border-radius: 6px;
        width: max-content;
        padding: 1rem 1.5rem;

        text-align: center;
        max-width: 200px;
        width: 100%;
        position: absolute;
        @include d(dt){
            max-width: 180px;
        }


        @include d(md2){
            // max-width: 106px;
            padding: 10px 14px;
            max-width: max-content;
            min-width: 102px;
        }

        &::before{
            content: '';
             position: absolute;
             height: 1px;

             bottom: 0;
             z-index: 1;
             left: 50%;
             transform: translateX(-50%);
             width: 63px;

        }

        &-1{
            color: #A3238E;
            top: 5rem;
            left: 0;
            @include d(dt){
                top: 1rem;
            }
            @include d(md2){
                top: -10px;
            }

            &::after{
                background-color: #A3238E;
            }
        }
        &-2{
            color: #56C5D0;

            bottom: 3.125rem;
            left: 0;
            @include d(dt){
                bottom: -40px;
            }
            @include d(md2){
                left: 40px;
                bottom: -40px;
            }
                &::after{
                background-color: #56C5D0;
            }
        }
        &-3{ color: #F58220;
            bottom: 3.75rem;
            right: 0;
            @include d(dt){
                bottom: 1rem;
            }
            @include d(md2){
                bottom: 4rem;
            }
            &::after{
                background-color: #F58220;
            }
        }


        .number{

            font-weight: 800;
            font-size: 3rem;
            line-height: 117%;
            /* identical to box height, or 117% */



            letter-spacing: -0.02em;
            text-transform: uppercase;

            /* Primary/Fucsia */
            @include d(dt){
                font-size: 2rem;
            }
            @include d(md2){
                font-size: 24px;
            }

        }
        .info{
            font-weight: 500;
            font-size: 1rem;
            line-height: 150%;
            /* identical to box height, or 150% */


            letter-spacing: -0.02em;
            text-transform: uppercase;

            /* Neutral/70 */

            color: #36363B;
            @include d(md2){
                font-size: 12px;
            }
        }

    }

    .img-wrapper{
        padding: 0 100px;
        position: relative;
        @include d(lg){
            padding: 0 60px;
        }
        @include d(md2){
            padding: 0 10px;
        }

        // img{
        //     // min-height: 400px

        // }
    }
    .button-wrapper{

        // box-shadow: 6px 6px #1d336f !important;
        a{
            @include BotaoMain(#D6DF21);

            // @include BotaoTeste(red, blue);
            //

        }
    }


    .alien-orna{
        position: absolute;
        bottom: -70px;
        right: 20vw;
        z-index: 2;

        @include d(lg){
            right: 20vw;
            bottom: -50px;
            max-width: 200px;
        }
        @include d(md){
            max-width: 130px;
            bottom: -100px;
            right: 1.5rem;
        }
    }

    .gatinho-orna{
        margin-bottom: -20px;
        @include d(md2){
            max-width: 123px;
            margin-left: 21px;

        }
    }
    .linear-orna{
        position: relative;

        img{
            min-height: 18px;
            width: 100%;
            object-fit: cover;
        }
    }


}

#atracoes{
    background-color:  $bienal-laranja;
    padding-bottom: 100px;
    background-image: url('../../src/assets/imagens/background-eventos.svg');
    position: relative;
    @include d(md2){
        background-size: 80px 80px;
    }
    // &::after{
    //     content: '';
    //     position: absolute;
    //     inset: 0;
    //     background: rgba(217, 217, 217, 0.2);
    // }

    .gatinho-orna{
        position: absolute;
        top: -140px;
        left: 24px;
        z-index: -1;
    }
    .alien-orna{
        position: absolute;
        top: -110px;
        left: 60vw;
        z-index: 2;

        @include d(lg){
            right: 20vw;
            bottom: -50px;
            max-width: 200px;
        }
        @include d(md){
            max-width: 130px;
            bottom: -100px;
            right: 1.5rem;
        }
    }
    .linear{
        position: absolute;
        top: 0;
        
        width: 100%;
    }
    .info-sujeita{
        color: #fff;
        letter-spacing: -.02em;
        text-transform: uppercase;
        font-style: normal;
        font-weight: 800;
        line-height: 114%;
        font-size: 1.8rem;
    }

    .section-title{
        h2{
            color: #fff;

            @include d(sm){
                max-width: 15ch;
            }
        }

        .badge-for-ornamento  .custom-tilt{
            transform: rotateZ(358deg);
            clip-path: unset !important;
            .badge{
                padding: 8px !important;
            }
        }

    }


    .info-atracoes-titulo{
        letter-spacing: -.02em;
        text-transform: uppercase;
        color: #fff;
        font-size: 2rem;
        font-style: normal;
        font-weight: 800;
        line-height: 114%;
      margin-top: 4rem;
      margin-bottom: 1rem;
    }

    .button-wrapper{
        flex-shrink: 0;
        a{
            @include BotaoMain(#D6DF21);
        }
    }

     .button-wrapper-bottom{
        @media (min-width: 768px){
            display: none;
        }
        .button{
            max-width: 100%;
            justify-content: center;
        }
     }


     .linear-orna{

        img{
            min-height: 18px;
            width: 100%;
            object-fit: cover;
        }
    }
}

#programacao{
    position: relative;


    .section-title{
        position: relative;
        width: max-content;
        margin: 0 auto;
        margin-bottom: 3.125rem;


        .ornamento-peixe{
            position: absolute;
            right: -4rem;
            top: 50%;
            transform: translateY(-57%);
        }
        h2{
            position: relative;
            z-index: 4;
        }
    }



    .swiper-programacao-tabs{
        margin-bottom: 2.5rem;

        .swiper-wrapper{
            justify-content: center;
            @include d(md){
                justify-content: unset;
            }
        }
        .swiper-slide{
            max-width: max-content;

        }
    }

    .tab-programacao{

        background-color: #F5872C;
        cursor: pointer;
        /* White

        FFFFFF
        */
        border: 1px solid #FFFFFF;
        border-radius: 4px;
        padding: 10px;
        color: rgba(255, 255,255, 0.6);


        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.5;


        text-align: center;
        letter-spacing: -0.02em;
        text-transform: uppercase;


        min-width: 140px;


        @include d(lg){
            min-width: 112px;
        }
        // @include d(lg){
        //     min-width: 120px;
        // }

        .programacao-data{
            font-size: 1.5rem;
            font-weight: 800;
            @include d(md2){
                line-height: 1;
            }
        }

        &.active{
            background: #A3238E;;
            color: #fff;
        }
    }


    .programacao-tab-content{
        display: none;
        // opacity: 0;
        // max-height: 0;
        // overflow: hidden;
        // transition: max-height  0.2s ease-in;
        min-height: max-content;
        position: relative;
        &.active{
            display: block;
            opacity: 1;
        }

        .gatinho-orna-2{
            position: absolute;
            top: -100px;
            left: 15px;

            @include d(dt){
                display: none;
            }
            @include d(md2){
                display: block;
                top: unset;
                bottom: 0;
                transform: scale(0.5);
                transform-origin: left;
            }

        }


        .grid-wrapper{
            display: grid;
            grid-template-columns: 0.3fr 1fr;
            gap: 1rem;
            color: #fff;
            z-index: 2;
            position: relative;
            @include d(md){
                grid-template-columns: 1fr;
                gap: 4px;
            }
        }


        .side-info{
            position: relative;
            border-radius: 4px;

            background-color: $bienal-rosa;
            text-align: center;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include d(md){
                grid-row: 2;
            }
            @include d(md2){
                background: transparent;
            }

            .content-side{
                padding: 2rem;
                margin: auto;
                @include d(lg){
                    padding: 1rem;
                }
                @include d(md2){
                    background-color: $bienal-rosa;
                    margin: unset;
                    border-radius: 4px;
                    margin-bottom: 4px;
                }
            }

            .img-detail{
                width: max-content;
                margin: 0 auto;
                margin-bottom: 2rem;
                @include d(md2){
                    position: absolute;
                    right: 0;
                    top: -20px;
                    margin-bottom: 0;
                    img{
                        height: 40px;
                    }
                }
                
            }

            .programacao-horario{
                margin-bottom: 8px;
                font-weight: 600;
                font-size: 3rem;
                line-height: 1.17;
                /* identical to box height, or 117% */

                text-align: center;
                letter-spacing: -0.02em;
                text-transform: uppercase;
                color: #FFFFFF;
                @include d(lg){
                    font-size: 2.5rem;
                }
                @include d(md2){
                    text-align: left;
                }
            }
            .programacao-local{
                font-weight: 500;
                font-size: 1.125rem;
                line-height: 1.4;


                text-align: center;
                letter-spacing: -0.02em;
                text-transform: uppercase;

                color: #FFFFFF;
                @include d(md2){
                    text-align: left;
                }
            }
            .bottom-info{
                background: #56C5D0;
                padding: 12px 0;

                @include d(md2){
                    border-radius: 4px;
                    padding:14px ;
                    margin-top: 2rem;

                }
                .button-bottom-info{
                    display: flex;
                    // align-items: center;
                    align-items: center;
                    justify-content: center;

                    font-weight: 700;
                    font-size: 14px;
                    line-height: 1;
                    text-align: center;
                    letter-spacing: -0.02em;
                    text-transform: uppercase;
                    color: #000000;




                    svg{
                        width: 1.25rem;
                        height: 1.3125rem;
                        margin-left: 4px;
                        flex-shrink: 0;
                        margin-top: -3px;
                    }
                }
            }
        }
        .programacao-dia-wrapper{
            background-color: $bienal-rosa;
            display: grid;
            grid-template-columns: 0.4fr 0.6fr;
            border-radius: 4px;

            @include d(md2){
                grid-template-columns: 1fr;
            }



            .swiper-programacao-atracoes{
                display: grid;
                width: 100%;

                @include d(md2){
                    // overflow: scroll;
                }


            }
            .swiper-controls-header{

                .buttons{
                    display: flex;
                    gap: 4px;
                    align-items: center;
                    .button{
                        background-color: $bienal-amarelo;
                        width: 1.5rem;
                        height: 1.5rem;
                        border-radius: 50%;
                        display: grid;
                        place-content: center;
                        svg{
                            path{

                                stroke: #000
                            }

                        }
                        &.swiper-button-disabled{
                            background-color: transparent;
                            border: 1px solid white;
                            svg{
                                path{

                                    stroke: #fff
                                }

                            }
                        }
                    }
                }

                .swiper-button-lock{
                    display: none !important;
                }
            }
            .dia-info-wrapper{
                padding: 3.5rem;
                border-right: 1px solid rgba(255, 255, 255, 0.2);

                @include d(lg){
                    padding: 2rem;

                }
                @include d(md2){
                    padding: 1.5rem;

                    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

                }

                .title{
                    font-weight: 800;
                    font-size: 1.5rem;
                    line-height: 1.3;
                    margin-bottom: 1rem;
                    /* identical to box height, or 133% */

                    letter-spacing: -0.02em;
                    text-transform: uppercase;

                    /* White */

                    color: #FFFFFF;
                }
                .descricao{
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5;
                    /* or 150% */

                    letter-spacing: -0.02em;

                    @include d(md2){

                        display: block;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;

                    }
                }
                .content-for-p{
                    &.open{

                        .descricao{
                            display: block;
                        }
                    }
                }


                .continuar-lendo{
                    display: flex;
                    align-items: center;
                    margin-top: 1rem;
                    cursor: pointer;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 1.5;
                    letter-spacing: -0.02em;
                    color: #D6DF21;
                    display: none;
                    @include d(md2){
                        display: flex;
                    }
                    svg{
                        flex-shrink: 0;
                        margin-left: 5px;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            .dia-atracoes-wrapper{
                padding: 3.5rem;
                display: grid;
                align-items: flex-start;
                width: 100%;
                @include d(lg){
                    padding: 2rem;
                }
            }

            .header-atracoes {
                align-self: flex-end;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 11px;

                .header-atracoes-titulo{
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 1.3;


                }
            }

            .card-programacao{
                cursor: pointer;
                
                .card-evento-img{
                    position: relative;
                    padding-top: 100%;
                    overflow: hidden;
                    img{
                        position: absolute;
                        inset: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        border-radius: 3px;
                        border-radius: 3px;
                    }
                }
                .card-evento-title{
                    font-weight: 700;
                    font-size: 1.125rem;
                    line-height: 1.4;

                    letter-spacing: -0.02em;
                    text-transform: uppercase;

                    color: #D6DF21;
                    display: block;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .card-evento-role{
                    font-weight: 500;
                    font-size: .875rem;
                    line-height: 157%;

                    letter-spacing: -0.02em;
                    text-transform: uppercase;
                    color: #FFFFFF;
                }
            }
        }
    }
}

#galeria {
    background-color:  $bienal-laranja;
    background-image: url('../../src/assets/imagens/background-eventos.svg');
    position: relative;
    height: 100%;

    .galeria-wrapper {
        padding: 7.5rem 0 3.5rem 0;

        .text-button {
            display: flex;
            justify-content: space-between;

            @include d(md){
                flex-direction: column;
            }

            h2{
                color: #fff;
            }

            .button-live{
                max-width: 12.65rem;
                display: flex;
                padding: .75rem 1rem;
                align-items: center;
                gap: 1.5rem;
                background: var(--bienal-amarelo);
                border-radius: .1875rem;
                color: var(--black);
                font-size: .875rem;
                font-style: normal;
                font-weight: 700;
                line-height: 157%; /* 157.143% */
                letter-spacing: -0.0175rem;
                text-transform: uppercase;
                box-shadow: 2.5px 2.5px #a11c64;

                &:hover{
                    transform: perspective(1px) translate3d(0, 2.5px, 0);
                }
            }
        }
    }

    .cards-wrapper {
        padding-bottom: 7.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .col-1,.col-2 {
            // display: grid;
            // gap: 1rem;
            // grid-template-columns: repeat(4,1fr);
            display: flex;
            gap: 1rem;
        }

        img {
            width: 23.75rem;
            height: 19.5rem;
            object-fit: cover;
        }
    }

}

#instagram{

    .badge-for-ornamento{
        max-width: max-content;
        margin: 0 auto;
    }
    .ornamento-camera{
        position: absolute;
        z-index: -1;
        left: -53px;
        bottom: -23px;
    }
    .section-title{
        max-width: max-content;
        margin: 0 auto;
        margin-bottom: 3.5rem;
    }
    .instagram-wrapper-new{

        #instafeed {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;

            @include d(md2){
                grid-template-columns: 1fr 1fr ;
            }



            a {
                width: 100%;
                overflow: hidden;
                position: relative;

                &:hover .content-hover{
                 transform: translateY(0);
                }

                .content-hover{
                    position: absolute;
                    pointer-events: none;
                    background-color: #D6DF21;
                    text-align: center;
                    padding: 2rem;
                    display: grid;
                    place-content: center;
                    gap: 10px;

                    inset: 0;
                    height: 100%;

                    overflow: hidden;
                    transform: translateY(100%);
                    transition: 0.2s ease;

                    .see-insta{
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 1.4;
                        /* identical to box height, or 144% */
                        text-align: center;
                        letter-spacing: -0.02em;
                        text-transform: uppercase;
                        /* Black */
                        color: #000000;
                        @include d(md2){
                            font-size: 1rem;
                        }
                    }
                    .click-icon{
                        margin-right: 2rem;

                    }
                    svg{
                    margin: 0 auto;
                        width: 2.0625rem;
                        height: 2rem;

                    }
                }
            }
        }


    }
    .banner-embaixo{
        display: flex;
        align-items: center;
        margin-top: 1rem;
        gap: 1rem;

        @include d(sm){
            flex-direction: column;
        }
    }

    .instagram-wrapper{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 8px;

        @include d(md2){
            grid-template-columns: repeat(2, 1fr);
        }
    }



    .img-wrapper{
        border-radius: 3px;
        overflow: hidden;
        position: relative;


        a{
            height: 100%;
            display: block;
            img{
                object-fit: cover;
                height: 100%;
            }
        }


        &:hover .content-hover{
            transform: translateY(0);
        }


        .content-hover{
            position: absolute;
            pointer-events: none;
            background-color: #D6DF21;
            text-align: center;
            padding: 2rem;
            display: grid;
            place-content: center;
            gap: 10px;

            inset: 0;
            height: 100%;

            overflow: hidden;
            transform: translateY(100%);
            transition: 0.2s ease;

            .see-insta{
                font-weight: 700;
                font-size: 18px;
                line-height: 1.4;
                /* identical to box height, or 144% */
                text-align: center;
                letter-spacing: -0.02em;
                text-transform: uppercase;
                /* Black */
                color: #000000;
                @include d(md2){
                    font-size: 1rem;
                }
            }
            .click-icon{
                margin-right: 2rem;

            }
            svg{
            margin: 0 auto;
                width: 2.0625rem;
                height: 2rem;

            }
        }


    }


}

#midia{


    .badge-for-ornamento{
        max-width: max-content;
        margin: 0 auto;
    }
    .ornamento-camera{
        position: absolute;
        z-index: -1;
        left: -53px;
        bottom: -23px;
    }
    .section-title{
        max-width: max-content;
        margin: 0 auto;
        margin-bottom: 3.5rem;
    }

}


.bottom-info-blog{

    display: flex;
    align-items: center;
    justify-content: space-between;

    svg{
        width: 24px;
        height: 24px;

        filter: brightness(0) invert(1);
    }
}

.blog-card {
    border-radius: 3px;
    overflow: hidden;

}
.blog-card.blog-destaque{
    // display: flex;


    &.half{
        display: grid;
        grid-template-columns: 1fr 1fr;

        @include d(dt){
            grid-template-columns: 1fr;
            grid-template-rows: 60% 40%;
            display: flex;
             flex-direction: column;
        }
    }
    &.half-bottom{
        display: grid;
        grid-template-rows: 60% 40%;
        @include d(dt){
            display: flex;
            flex-direction: column;
        }
    }

    .img-wrapper{
        @include d(dt){
            flex: 1;

            position: relative;
            padding-top: 75%;
            overflow: hidden;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include d(dt){
                position: absolute;
                inset: 0;
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }
    }




    .content-blog{
        background: #A3238E;
        padding: 2rem;
        color: #fff;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include d(dt){
            padding: 1rem;
            flex: 1;
        }

        h2{
            font-style: normal;
            font-weight: 600;
            font-size: 2rem;
            line-height: 1.1;
            /* or 106% */
            letter-spacing: -0.02em;
            text-transform: uppercase;
            flex: 1;
            margin-bottom: 1rem;


            display: flex;
            justify-content: flex-start;
            align-items: center;
            @include d(lg){
                font-size: 1.5rem;
            }
            @include d(dt){
                font-size: 1.2rem;
            }
        }

        .info-blog{
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.5;
            /* identical to box height, or 150% */

            letter-spacing: -0.02em;
            text-transform: uppercase;

            color: rgba(255, 255, 255, 0.8);
            display: flex;
            flex-wrap: wrap;
            column-gap: 5px;
        }
    }
}


@media (min-width:768px){
    .swiper-midia {
        display: none;

    }

}

.swiper-midia{
    overflow: visible;
    .swiper-slide{
        height: auto;

        .blog-card{
            height: 100%;
            display: flex;
            flex-direction: column;

            .img-wrapper{
                flex: 1;
            }

            .content-blog{
                flex: unset;
            }
        }
    }

    .swiper-slide:nth-child(1) {
        .content-blog{
            background-color:  $bienal-rosa;
        }
    }
    .swiper-slide:nth-child(2) {
        .content-blog{
            background-color:  #369AA3;
        }
    }
    .swiper-slide:nth-child(3) {
        .content-blog{
            background-color:  $bienal-laranja;
        }
    }
    .swiper-slide:nth-child(4) {
        .content-blog{
            background-color:  #369AA3;
        }
    }

    .todas-noticias{


        border: 1px solid #0F0F12;
        border-radius: 3px;

        display: block;
        padding: 10px;
        margin-top: 1rem;

        p{

            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            /* identical to box height, or 157% */

            text-align: center;
            letter-spacing: -0.02em;
            text-transform: uppercase;

            /* Black */

            color: #000000;
        }

        svg{
            width: 20px;
            height: 20px;
        }
    }
}

.blog-grid-wrapper{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 27.8125rem 27.8125rem;
    gap: 2rem;

    @media( max-width: 768px){
        display: none;
    }
    @include d(lg){
        gap: 1rem;
        grid-template-rows: 25rem 25rem;
    }

    @include d(dt){
        grid-template-columns: 1fr 1fr;
        grid-template-rows: unset;

    }
    @include d(md2){
        grid-template-columns: 1fr;
    }

    .blog-card:nth-child(1){
        grid-template-columns: 1.6fr 1.4fr;
        grid-column: 1/ 4;

        @include d(dt){
            grid-template-columns: 1fr;
            grid-column: unset;
            // display: block;
        }




        .content-blog{
            background-color: $bienal-rosa;
        }
        img{
            max-height: 445px;
        }
    }
    .blog-card:nth-child(2){
        grid-column: 4 / 6;

        @include d(dt){
            grid-column: unset;
        }

        .content-blog{
            background-color:  #369AA3;
        }
    }
    .blog-card:nth-child(3){
        grid-row: 2;
        grid-column: 1 / 3;

        @include d(dt){
            grid-column: unset;
            grid-row: unset;
        }
        .content-blog{
            background-color:  $bienal-laranja;
        }
    }
    .blog-card:nth-child(4){
        grid-row: 2;
        grid-column: 3 / 5;

        @include d(dt){
            grid-column: unset;
            grid-row: unset;
        }
        .content-blog{
        background-color:  #369AA3;
         }
    }

    .todas-noticias{
        border: 1px solid #0F0F12;
        background: #FFFFFF;
        border-radius: 3px;
        padding: 1rem;
        display: grid;
        place-content: center;
        @include d(dt){
            grid-column:  span 2;
        }
        @include d(md2){
            grid-column: unset;
        }
        p{
            display: flex;
            align-items: center;

            font-weight: 700;
            font-size: 14px;
            line-height: 1.5;

            letter-spacing: -0.02em;
            text-transform: uppercase;
        }
        svg{
            width: 20px;
            margin-bottom: 5px;
            height: 21px;
        }
    }
}



#youtube{
    background-color:  $bienal-laranja;

    background-image: url('../../src/assets/imagens/background-eventos.svg');
    position: relative;
    @include d(md2){
        background-size: 80px 80px;
    }

    @media (min-width:1024px){

        .swiper-pagination{
            position: unset;
            margin-right: 2rem;
            max-width: 80px !important;
        }
    }
    .swiper-pagination{
        max-width: 80px !important;
    }
    .controls{
        display: flex;
        align-items: center;
        margin-bottom: 10px;



            .buttons{
                flex-shrink: 0;
                display: flex;
                align-items: center;
                gap: 1rem;
                @include d(md){

                  display: none;

                }

                .button{
                    padding: 8px 16px;
                    gap: 8px;

                    width: 56px;
                    height: 56px;
                    display: grid;
                    place-content: center;
                    /* Primary/Verde

                    D6DF21
                    */
                    background: #D6DF21;
                    border-radius: 3px;

                    svg{
                        width: 24px;
                        height: 24px;
                    }
                    &.button-left{

                        svg{
                            transform: rotate(180deg);
                        }
                    }
                }

                .swiper-button-disabled{
                    background: #E3E3E6;
                }

            }
    }


    .swiper-youtube{
        @include d(md){
            overflow: visible;
        }

        .swiper-wrapper{
            @include d(md){

                padding-bottom: 4rem;
            }
        }
        .swiper-slide{
            height: auto;
            > a{
                height: 100%;
                display: grid;
            }
        }

    }



    .swiper-pagination{

        .swiper-pagination-bullet {
            opacity: 1;
            background: rgba(255, 255, 255, 0.36);;
        }
        .swiper-pagination-bullet-active{
            background-color: $bienal-amarelo;
            width: 16px;
            border-radius: 8px;
        }
    }


    .section-title{
        h2{
            color: #fff;
        }

    }


    .card-youtube{
        color: #fff;
        // max-width: 512px;
        display: flex;
     flex-direction: column;

        .img-wrapper{
            margin-bottom: 1.5rem;
            // flex: 1;
            padding-top: 56.25%;
            position: relative;

            @include d(md){
                margin-bottom: 1rem;
            }

            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
                border-radius: 6px;

                position: absolute;
                inset: 0;
            }
        }

        .data{
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.5;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: $bienal-rosa;
            margin-bottom: 8px;
        }
        .video-title{
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 1.3;

            letter-spacing: -0.02em;
            text-transform: uppercase;

            color: #FFFFFF;

            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            height: 3.5625rem;



        }
    }
}


#contato-footer{

    background: #F3F3F5;
    padding-bottom: 9.375rem;
    scroll-margin-top: 130px;

    @include d(md2){
        background-color: transparent;
        padding-bottom: 0;
    }

    .contato-grid-wide{
        display: grid;
        grid-template-columns: 0.8fr 1.2fr;
        align-items: center;

        @include d(md2){
            grid-template-columns: 1fr;
        }
    }

    .col-1{
        position: relative;
        align-self: stretch;




        .orna{
            position: absolute;
            z-index: 2;



            &-1{
                top: 9%;
                right: -2rem;
                @media (max-width: 1400px){
                    max-width: 60px;

                }

                @include d(md2){
                    top: -24px;
                    left: 3.875rem;
                    max-width: 47px;
                }

            }
            &-2{
                left: 160px;
                bottom: 119px;
                @media (max-width: 1400px){
                    left: 120px;
                    bottom: 89px;

                }
                @include d(md2){
                    left: 100px;
                    bottom: 74px;
                }
            }
            &-3{
                bottom: 0;
                left: 0;

                @media (max-width: 1400px){

                    max-width: 120px;

                }
                @include d(md2){
                    max-width: 100px;
                }

            }
            &-4{
                bottom: -40px;
                right: 20%;

                @media (max-width: 1400px){
                    max-width: 120px;

                }
                @include d(md2){
                    display: none;
                }

            }

        }
        .img-wrapper{
            height: 100%;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .col-2{
        padding-top: 7.5rem;
        padding-left: 10rem;
        padding-bottom: 0rem;
        padding-right:  10rem;
        position: relative;

        @media (max-width: 1600px){

            padding-left: 6rem;
            padding-top: 5rem;
        }
        @include d(md){
            padding-right:  3rem;
            padding-left: 3rem;
        }
        @include d(md2){
            grid-row: 1;
            padding-right:  1rem;
            padding-left: 1rem;
            padding-bottom: 4.5rem;
        }

        .contato-girl-orna{

            position: absolute;
            right: 7.5rem;
            bottom: -5rem;

            @media (max-width: 1760px){
                right: 0;
            }
            @include d(md){
                display: none;

            }
        }

        .limit-width{
            max-width: 635px;
            padding-right: 50px;
            position: relative;
            z-index: 1;

            @include d(md){
                padding-right: 0;
            }
            @include d(md2){
                max-width: 540px;
                margin: 0 auto;
            }
        }


    }


    .contato-wrapper{
        margin-bottom: 3.5rem;

        .label-texto{
            font-weight: 500;
            font-size: 1.125rem;
            line-height: 144%;
            /* identical to box height, or 144% */
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: $bienal-rosa;
            margin-bottom: .75rem;
        }

        .contato-texto{
            font-weight: 800;
            font-size: 2.5rem;
            line-height: 140%;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: #000000;

            margin-bottom: 1rem;
            @media (max-width: 1360px){
                font-size: 2.3rem;
            }
            @include d(dt){
                font-size: 2rem;
            }
            @include d(md2){
                font-size: 2.2rem;
            }
        }

        .contato-descricao{
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 1.4;


            letter-spacing: -0.02em;



            color: #36363B;
        }


    }

    .form-wrapper{

        .button-wrapper{
            transition: 0.2s ease-in;
            &:hover{
                transform: scale(1.02);

            }
            .button{
                background-color: $bienal-amarelo;

                display: flex;
                align-items: center;
                padding: 13px 16px;
                font-weight: 700;
                font-size: 14px;
                line-height: 1.5;
                /* identical to box height, or 157% */

                letter-spacing: -0.02em;
                text-transform: uppercase;

                /* Black */

                color: #000000;
                @include d(md2){
                    width: 100%;
                    justify-content: center;
                }
            }
            svg{
                width: 24px;
                height: 24px;
                margin-left:1.5rem;
            }
        }
    }
    .info-contato-container{
        margin-top: 2rem;
        .block-wrapper-contato{
            margin-bottom: 1.125rem;
            display: flex;
            align-items: flex-start;
            gap: 1rem;
            .block-title{
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 1.5;
                /* identical to box height, or 150% */
                letter-spacing: -0.02em;
                text-transform: uppercase;
                /* Primary/Fucsia */
                color: #A3238E;





            }

            .block-info{
                display: block;
                font-weight: 400;
                font-size: 1.125rem;
                line-height: 1.4;

                /* identical to box height, or 144% */

                letter-spacing: -0.02em;

                /* Neutral/70 */

                color: #36363B;
            }
        }
    }

}

#frases-home{
    background: #2E3192;
    padding: 64px 0;
    .frases-wrapper{
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: center;
        max-width: 1260px ;
        margin: 0 auto;

        .frase{
            text-align: center;
            font-family: "Extatica-new";
            font-size: 45px;
            font-style: normal;
            font-weight: 800;
            line-height: 90%; /* 80.6px */
            letter-spacing: -1.86px;
            text-transform: uppercase;

            @media(max-width:1000px){
             font-size: 32px;
                
            }

            @media(max-width:600px){
                font-size: 24px;
                font-weight: 700;

                   
               }

        }
        .laranja{
            color: #F58220;
        }
        .azul{
            color: #56C5D0;
        }
        .branca{
            color: #fff;
        }
        .rosa{
            color: #C940B2;
        }
        .verde{
            color: #D6DF21;
            font-size: 52px;
            @media(max-width:1000px){
                font-size: 32px;
                   
               }
   
               @media(max-width:600px){
                   font-size: 24px;
                   font-weight: 700;
   
                      
                  }
        }
    }
}



#expositores-swiper{
    margin: 160px 0;
    @media(max-width:800px){
        margin: 60px 0;


    }

    .expositores-titulo{
        color:  #2E3192;
        text-align: center;
        font-family: "Extatica-new";
        font-size: 56px;
        font-style: normal;
        font-weight: 800;
        line-height: 64px; 
        letter-spacing: -1.12px;
        text-transform: uppercase;
        margin-bottom: 64px;
        @media(max-width:800px){
            font-size: 32px;
            margin-bottom: 32px;
            line-height: 42px; 

        }
    }

    .expositoresSwiper{

        .swiper-slide{
            width: auto;

            img{
                max-width: 391px;
                width: 100%;
                max-height: 391px;
                height: 100%;
            }
        }
    }
}