.coming-soon{
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #000;

  .logo{
    height: 160px;
    width: 160px;
    margin-top: 32px;
    margin-left: 32px;
  }

  video{
    width: 100%;
    height: 80%;
  }

  footer{
    background-color: #A3238E;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}