#contato-bienal{
    background-color: #fff;

    .section-title{
        margin-bottom: 3.5rem ;
        .description{
            margin-top: 1rem;
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 1.4;
            /* or 144% */

            letter-spacing: -0.02em;

            /* Neutral/70 */

            color: #36363B;
            max-width: 54ch;
        }
    }
    .actions{
        @media(max-width:750px){
            margin-top: 0px !important;
        }
    }

    
    .info-contato-container{
        margin-top: 2rem;
        .block-wrapper-contato{
            margin-bottom: 1.125rem;
            display: flex;
            align-items: flex-start;
            gap: 1rem;
            .block-title{
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 1.5;
                /* identical to box height, or 150% */            
                letter-spacing: -0.02em;
                text-transform: uppercase;           
                /* Primary/Fucsia */            
                color: #A3238E;
    
                
                
              
                
            }
    
            .block-info{
                display: block;
                font-weight: 400;
                font-size: 1.125rem;
                line-height: 1.4;
                
                /* identical to box height, or 144% */
    
                letter-spacing: -0.02em;
    
                /* Neutral/70 */
    
                color: #36363B;
            }
        }

        .redes-sociais-contato{
            margin-top: 24px;
            padding-top: 24px;
            border-top: 1px solid rgba(15, 15, 18, 0.10);
            flex-direction: column;

            .icones-wrapper{
                display: flex;
                align-items: center;
                gap: 8px;

                svg{
                    width: 40px;
                    height: 40px;

                    transition: transform 0.3s ease-in-out; 
                    
                }

                a svg:hover {
                    transform: translateY(-3px); 
                    transition: transform 0.3s ease-in-out; 
                }
            }
            
        }
    }


    .grid-wrapper{

        display: grid;
        grid-template-columns: 1fr 1fr;
        @include d(md){
            grid-template-columns: 1fr;
        }
    }


    .col-1{
        max-width: 512px;
        padding: 120px 0;
        margin-right: 2rem;
        @include d(md){
            padding: 5rem 0;
        }
      
    }


    .col-2{
            position: relative;
            padding: 120px 0;

            display: flex;
            flex-direction: column;
            justify-content: center;

            @include d(sm){
                padding: 1.5rem 0;
            }

            &::before{
                content: '';
                position: absolute;
                
                // background-image: url('../../src/assets/imagens/contato/bg-contato.webp');
                 background-image: url('../../src/assets/imagens/contato/contato-new.webp');
                 background-position: 25%;
                background-repeat: no-repeat;
                background-size: cover;
                // background-size: cover;
                width: 51vw;
                height: 100%;
                top: 0;
                left: 136px;
                bottom: 0;
                @include d(md){
             
                    width: 120vw;
                    left: -20vw;
                }
            }


        .form-wrapper{
            width: 100%;
            max-width:470px; 
            position: relative;
            z-index: 2;

            background: #FFFFFF;
            /* Neutral/10% OP
            0F0F12
            */
            border: 1px solid rgba(15, 15, 18, 0.1);
            border-radius: 3px;
            padding: 4rem 2.5rem;

            @include d(sm){
                padding: 2.5rem 2rem;
            }
            
            .form-title{
                font-weight: 500;
                font-size: 24px;
                line-height: 1.33;
                /* identical to box height, or 133% */
                margin-bottom: 2.5rem;

                letter-spacing: -0.02em;
                text-transform: uppercase;

                /* Primary/Fucsia */

                color: #A3238E;
            }

            .button-wrapper{
                transition: 0.2s ease-in;
                &:hover{
                    transform: scale(1.02);
                   
                }
                .button{
                    background-color: $bienal-amarelo;
                    width: 100%;
                    border-radius: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 13px 16px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 1.5;
                    /* identical to box height, or 157% */
    
                    letter-spacing: -0.02em;
                    text-transform: uppercase;
    
                    /* Black */
    
                    color: #000000;
                    @include d(md2){
                        width: 100%;
                        justify-content: center;
                    }
                }
                svg{
                    width: 24px;
                    height: 24px;
                    margin-left:1.5rem;
                }
            }
        }
    }
}


#programacao-rolling{
    background-color: #fff;
    .swiper-infinite-programacao{
        .swiper-wrapper{
            transition-timing-function: linear;
        }
        .swiper-slide{
            width: max-content;
            max-width: 100%;
            min-width: max-content;
        }
    }
    .item-infinite{
        display: flex;
        align-items: center;
        min-width: max-content;
        h2{
            font-weight: 700;
            font-size: 6rem;
            line-height: 1.2;
            /* identical to box height, or 108% */
            letter-spacing: -0.03em;
            text-transform: uppercase;
            /* Neutral/30 */
            color: #E3E3E6;
            min-width: max-content;

            @include d(lg){
                font-size: 5rem;
            }
            @include d(md){
                font-size: 3rem;
            }
        }

        .dot-right{
            width: 1rem;
            height: 1rem;
            min-width: 1rem;
            min-height: 1rem;
            background-color: #E3E3E6;;
            border-radius: 50%;
            margin-left: 64px;
            @include d(md){
                margin-left: 20px;
            }
        }
    }

}


