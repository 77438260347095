#ao-vivo{
    .banner-img{
        width: 100%;
        object-fit: cover;
    }

    .img-wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.625rem;
        margin: 6rem 0 10rem 0;

        @include d(md){
            grid-template-columns: 1fr;
        }

        .text-content{
            position: relative;

            .text {
                padding: 3rem;
                position:absolute;
                bottom: 0;

                @include d(sm){
                    padding: 2rem;
                }

                h2{
                    color: var(--white);
                    font-size: 5rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 100%; /* 100% */
                    letter-spacing: -0.1rem;
                    margin-bottom: 2rem;

                    @media(max-width:1600px){
                        font-size: 4rem;
                    }

                    @media(max-width:1400px){
                        font-size: 3rem;
                    }

                    @include d(sm){
                        font-size: 2rem;
                    }
                }

                .button-live{
                    max-width: 8.1875rem;
                    display: flex;
                    padding: .75rem 1rem;
                    align-items: center;
                    gap: 1.5rem;
                    background: var(--bienal-amarelo);
                    border-radius: .1875rem;
                    color: var(--black);
                    font-size: .875rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 157%; /* 157.143% */
                    letter-spacing: -0.0175rem;
                    text-transform: uppercase;
                    box-shadow: 2.5px 2.5px #a11c64;

                    &:hover{
                        transform: perspective(1px) translate3d(0, 2.5px, 0); 
                    }

                    svg{
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                }
            }
        }
    }
}