.input-group{
    margin-bottom: 1rem;

    input,
    textarea{
        width: 100%;

        background: #FFFFFF;
        /* Neutral/10% OP

        0F0F12
        */
        border: 1px solid rgba(15, 15, 18, 0.1);
        border-radius: 3px;
        padding: 12px 14px;

        &:focus{
            border-color: #000;
        }

        &::placeholder{
            color: #525C64;
           
        }
    }
    textarea{
        resize: none;
    }
}