.section-title{
    position: relative;

    .badge-wrapper{

        width: max-content;
        position: relative;
        
        transform: skew(3deg);
       
        clip-path: polygon(6% 10%, 100% 0, 94% 90%, 0% 100%);
        margin-bottom: 10px;

        background-color: #D6DF21;
        color: #000000;

        &.bg-amarelo{
            background-color: $bienal-amarelo;        
        }
        &.bg-rosa{
            background-color:  $bienal-rosa;
            color: #fff;
           
        }
        &.bg-laranja{
            background-color:  $bienal-laranja;
        }

        &.bg-azul{
            background-color: #56C5D0;
            color: #000;
        }

    
        .badge{
            text-transform: uppercase;
            line-height: 1;
            padding:  5px 15px;
            width: max-content;              
            position: relative;    
            transform: skew(-3deg) ;   
            
            

            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
           
            /* identical to box height, or 138% */

            letter-spacing: 0.04em;
            text-transform: uppercase;

            /* Black */

           
                                
        }
    }

    h2{
        font-style: normal;
        font-weight: 800;
        font-size: 3.5rem;
        line-height: 114%;
        /* or 114% */

        // letter-spacing: -0.02em;
        letter-spacing: -0.02em;
        text-transform: uppercase;

        /* Black */

        color: #000000;
        @include d(lg){
            font-size: 3rem;
        }
        @include d(md2){
            font-size: 2.5rem;
        }
    }
    .section-description{
       
        font-style: normal;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 144%;
        /* or 144% */
        margin-top: 2rem;
        letter-spacing: -0.02em;

        /* Neutral/70 */

        color: #36363B;
    }

}