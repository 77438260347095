&{

    @media (min-width:1024px){
        overflow-x:initial;

        main{
            overflow: initial;
        }
    }
}


#galeria-bienal{

    .grid-programacao{
        display: grid;
        grid-template-columns: 23.4375rem 1fr;
        align-items: flex-start;
        gap: 2rem;

        @include d(lg){
            grid-template-columns: 19rem 1fr;
        }

        @include d(md){
            grid-template-columns: 1fr;
        }
       
    }

    .div-to-sticky{
        position: sticky;
        top: 140px;


        .img-menina{
            // position: absolute;
            // bottom: 0;
            margin-top: 100px;
            @include d(md){
                display: none;
            }
        }
        .filtro-title{
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
            letter-spacing: -0.32px;
            text-transform: uppercase;
            margin-bottom: 8px;
        }

        .accordion {
            display: block;
            margin-bottom: 10px;
          }
        
          .accordion-header {
            background-color: #f4f4f4;
            padding: 10px;
            cursor: pointer;
          }
        
          .accordion-content {
            display: none;
            padding: 10px;
          }

        .espacos{
            display: grid;
            border-radius: 3px;
            border: 1px solid var(--l-neutral-10-op, rgba(1, 7, 27, 0.10));
            background: var(--generics-white, #FFF);
            box-shadow: 0px 11px 15px 0px rgba(1, 7, 27, 0.08),
             0px 9px 46px 0px rgba(0, 0, 0, 0.04),
             0px 24px 38px 0px rgba(0, 0, 0, 0.02);
            margin-bottom: 15px;
            transform: translateY(-20px);
            display: none;
            
            .espaco-item{
                padding: 9px;
                border-radius: 3px;
                border: 1px solid var(--neutral-10-op, rgba(15, 15, 18, 0.10));
                background: var(--white, #FFF);
                margin:8px 12px 8px 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                transition: 0.3s ease-in-out;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px; /* 150% */
                letter-spacing: -0.32px;
                text-transform: uppercase;
                cursor: pointer;
                svg{
                    width: 24px;
                    height: 24px;
                }
                &:hover{
                    transition: 0.3s ease-in-out;
                   
                    transform: scale(1.01);
                }
            }
        }
        .espacos.open{
            display: block;
            
        }

        .custom-select {
            position: relative;
            border: 1px solid rgba(15, 15, 18, 0.1);
            border-radius: 3px;
            padding: 12px 16px;
            cursor: pointer;
            margin-bottom: 1rem;
            background: #fff;
            
        }
        
          /* Estilo da opção selecionada */
        .custom-select select {
            display: none;
        }
        
          /* Estilo do rótulo da opção selecionada */
        .select-selected {
            display: flex;
            justify-content: space-between;
            
            
            cursor: pointer;
            
            .svg-pesquisa{
                width: 32px;
                height: 32px;
                transform: rotate(180deg);
                transition: 0.2s ease-in-out;
            }
        }
        
          /* Estilo das opções do dropdown */
        .select-items {
            display: none;
            position: absolute;
            left: 0;
            background-color: #f9f9f9;
            border: 1px solid #ccc;
            max-height: 150px;
            overflow-y: auto;
            z-index: 1;
            border-radius: 3px;
            border: 1px solid var(--l-neutral-10-op, rgba(1, 7, 27, 0.10));
            background: var(--generics-white, #FFF);
            box-shadow: 0px 11px 15px 0px rgba(1, 7, 27, 0.08), 0px 9px 46px 0px rgba(0, 0, 0, 0.04), 0px 24px 38px 0px rgba(0, 0, 0, 0.02);
            }
        
        .select-items div {
            margin-top: 8px;
            width: 375px;
            padding: 8px 10px;
            cursor: pointer;
            border-radius: 3px;
            border: 1px solid var(--neutral-10-op, rgba(15, 15, 18, 0.10));
            background: var(--white, #FFF);
        }
        
          /* Estilo das opções do dropdown quando passa o mouse */
        .select-items div:hover {
            background-color: #ddd;
        }

        //select estilo
        
    }

    .sidebar{
        border: 1px solid rgba(15, 15, 18, 0.1);
        border-radius: 3px;
        padding: 1rem;
        background-color: #fff;

        @include d(md){
            overflow: hidden;
            
            border-width: 0;
            padding: 0;
            transition: 0.2s ease-in;
            &.open{

                max-height: 1000px;
                      
                border-width: 1;
            }

            ul{
                border: 1px solid rgba(15, 15, 18, 0.1);
            }
        }
    
    
        .dias-wrapper{

            li{
                padding: 12px;
                cursor: pointer;
                .dia-semana{
                    font-weight: 500;
                    font-size: 14px;
                    line-height:1.5;
                    /* identical to box height, or 157% */
    
                    
                    letter-spacing: -0.02em;
                    text-transform: uppercase;
    
                    /* Neutral/50% OP */
    
                    color: rgba(15, 15, 18, 0.5);
                }
                .dia-data{
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 26px;
                    /* identical to box height, or 144% */
    
                    
                    letter-spacing: -0.02em;
                    text-transform: uppercase;
    
                    /* Primary/Fucsia */
    
                    color: #A3238E;
    
    
                }
                &.active{
                    background-color: $bienal-rosa;
                    border:  4px solid #FFCD29;
                    padding: 12px;
                    color: #fff;
                    
                    .dia-semana{
                        color: #D6DF21;
                        font-weight: 700;
                    }
                    .dia-data{
                        color: #FFFFFF;
                    }
                }
             
            }
        }
       
    }

    .image-galeria-wrapper{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap: .5rem;

        img {
            width: 23.75rem;
            height: 19.5rem;
            object-fit: cover;
        }

        @media (max-width:1400px) {
            grid-template-columns: repeat(3,1fr);
        }

        @include d(md){
            grid-template-columns: 1fr;
            justify-items: center;  
        }
    }
}