#expositores{
 position: relative;
    // margin-bottom: 7.5rem;
    // padding-bottom: 25rem;
    min-height: 760px;
    @include d(md2){
        margin-bottom: 5rem;
    }

    .robozin-wrapper{
        position: absolute;
        top:  7.5rem;
        img{
            max-width: 132px;
        }
    }
    .livro-rosa-wrapper{
        position: absolute;
        top:  2rem;
        right: 0;
        transform: rotate(100deg);
        
    }

    .container-limit{
        max-width: calc(1328px + 200px);
        margin: 0 auto;
        padding: 0 100px;
        @include d(lg){
            padding: 0;
        }

        .barra-expositores{
            border: 1px solid  rgba(15, 15, 18, 0.10);
            background:  #F3F3F5;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: center;
            gap: 4px;
            padding: 1.5rem;
            display: grid;
            margin-bottom: 1rem;
            @media screen and (max-width: 768px){
                grid-template-columns: 1fr;
            }

            p{
                color: rgba(15, 15, 18, 0.50);
                font-family: "Extatica-new";
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px; 
                letter-spacing: -0.36px;
                text-transform: uppercase;
            }

        }
    }

    .title-wrapper{

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 7.5rem;
        margin-bottom: 2.5rem;

        @include d(md2){
            display: block;
            padding-top: 5rem;
        }

        h2{
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 133%;
            /* identical to box height, or 133% */

            letter-spacing: -0.02em;
            text-transform: uppercase;

            /* Neutral/100 */

            color: #0C1428;
        }

        .title-for-orna{
            display: flex;
            gap: 2rem;
            align-items: center;
            
            .img-wrapper{
                margin-bottom: 2rem;
                img{
                    max-width: 74px;
                }
            }
          
        }


        .floating-letters{
            position: absolute;
            left: 0;
            right: 0;
            top: calc(100% + 1rem);
            z-index: 10000;
            background-color: #fff;
            max-width: 300px;
          
            border: 1px solid rgba(1, 7, 27, 0.1);
            box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.02), 0px 9px 46px rgba(0, 0, 0, 0.04), 0px 11px 15px rgba(1, 7, 27, 0.08);
            border-radius: 3px;
            padding: 1rem;

            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: flex-start;
            
            gap: 8px;
            margin-right: -1px;
            
            pointer-events: none;
            display: none;


            a{
                flex: 0 0 calc(33% - 8px);
                display: block;
                background-repeat: no-repeat;
                border: 1px solid rgba(1, 7, 27, 0.1);
                background-position: 97%;
                padding: .4rem .5625rem;
                cursor: pointer;
                padding-right: 1rem;
                background-image: url('../../src/assets/imagens/icons/arrow-right-busca.svg');


                font-weight: 700;
                font-size: 16px;
                line-height: 150%;
                /* identical to box height, or 150% */

                
                letter-spacing: -0.02em;
                text-transform: uppercase;


                color: #36363B;

                &.active{
                    background-color: rgba(245, 130, 32, 0.1);
                    border: 1px solid rgba(245, 130, 32, 0.1);
                    border-radius: 3px;
                }
            }
        }

        .filtro-to-click.show{

            .floating-letters{
                pointer-events: all;
                display: grid;
            }
           
        }
    }

    .buscar-filtro{
        form{
            display: flex;
            align-items: center;
            gap: 12px;
            background-color: #fff;
        }

        label{
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            /* identical to box height, or 150% */

            letter-spacing: -0.02em;
            text-transform: uppercase;

            /* Neutral/70 */

            color: #36363B;
        }

        .filtro-to-click{
            border: 1px solid rgba(15, 15, 18, 0.1);
            border-radius: 3px;
            padding: .75rem;
            min-width: 260px;
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            position: relative;
            z-index: 3;


           
            p{
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                /* identical to box height, or 150% */
    
                letter-spacing: -0.02em;
                text-transform: uppercase;
    
                /* Neutral/50% OP */
    
                color: rgba(15, 15, 18, 0.5);
            }
        }
        .circle-arrow{
            background-color: #D6DF21;
            display: grid;
            place-content: center;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
        }
    }

    
    .expositores-grid{
        display: grid;
        grid-template-columns: 1fr ;
        gap: 1rem;
        align-items: flex-start;
        margin-bottom: 4rem;
        @include d(md){
            grid-template-columns: 1fr;
        }

        .expositor-item{
            padding: 1.5rem;
            background: #FFFFFF;
            /* Primary/Fucsia

            A3238E
            */
            border: 1px solid #A3238E;

            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 4px;
            align-items: center;

            @include d(md2){
                grid-template-columns: 1fr;
            }

            .nome{
                font-weight: 500;
                font-size: 1.125rem;
                line-height: 140%;
                /* identical to box height, or 144% */

                letter-spacing: -0.02em;
                text-transform: uppercase;

                /* Primary/Fucsia */

                color: #A3238E;
            }

            .estande{
                font-weight: 500;
                font-size: 1.125rem;
                line-height: 140%;
                /* identical to box height, or 144% */

                letter-spacing: -0.02em;
                text-transform: uppercase;

                /* Neutral/70 */

                color: #36363B;
            }

            
        }
    }
}


.pagination{


    .page-list{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .75rem;

        a{
            padding: .375rem .875rem;
            border: 1px solid rgba(15, 15, 18, 0.1);
            border-radius: 3px;
            display: block;
        }
    }

    li{
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 3px;
        /* identical to box height, or 150% */

   
        letter-spacing: -0.02em;
        text-transform: uppercase;

        color: #000000;

        &.current{
            background-color: #000;
            a{
                color: #fff;

            }
        }
    }

    .on-edges{
        
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5;
        /* identical to box height, or 150% */

        text-align: center;
        letter-spacing: -0.02em;
        text-transform: uppercase;

  
        background-color: $bienal-amarelo;
        /* Black */

        color: #000000;
        &.left{
                svg{
                    transform: rotate(180deg);
                    margin-right: 8px;
                    align-self: flex-start;
                }
        }
        &.right{
            svg{
                margin-left: 8px;
                align-self: flex-start;
            }

        }
       
        a{
            display: flex;
            align-items: center;
        }
        svg{
            width: 20px;
            height: 20px;

        }
    }
}


.active-step-1{

    .step-1-item{
        display: block;
    }
    .step-2-item{
        display: none;
    }

}
.active-step-2{
    .step-1-item{
        display: none;
    }
    .step-2-item{
        display: block;
    }
    

}

// MODAL 

.modal-infos{

    .header-expositor{
        position: relative;
        padding-left: 3rem;
        @include d(md2){
            padding-left: 0;
        }
        &::after{
            content: '';
            height: 4px;
            width: calc(100% + 50px);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #D6DF21;
            @include d(md2){
                width: 100%;
                display: none;
            }
        }
        
    }
    .info-label{
        color: rgba(255, 255, 255, 0.50);
        font-size: 14px;
        padding-top: 1rem;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.28px;
        text-transform: uppercase;
    }
    .info-nome{
        color:  #FFF;
        font-size: 24px;
    
        font-weight: 700;
        line-height: 26px;
        letter-spacing: -0.48px;
        text-transform: uppercase;
    }

    .header-1-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
 

    .header-2-wrapper{
        border-top: 1px solid rgba(255, 255, 255, 0.20);
        display: grid;
        grid-template-columns: 0.3fr 1fr;
        gap: 1.5rem;
        // gap: 1rem;
       
        .nome-wrapper{
            padding-bottom: 1rem;
            border-right: 1px solid #fff3;
        }
        .info-nome{
            font-size: 1.125rem;
        }
        .nome-wrapper:first-child{
            
            border-bottom: 1px solid #fff3;
            padding-bottom: 1rem;
        }

        .descricao{

            .conteudo{
                color:  #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px; /* 144.444% */
                letter-spacing: -0.36px;
            }
        }

    }

    .button-wrapper{
        transition: 0.2s ease-in;
        @include d(md2){
            display: none;
        }
        &:hover{
            transform: scale(1.02);
           
        }
        .button{
            background-color: $bienal-amarelo;
    
            display: flex;
            align-items: center;
            padding: 13px 16px;
            font-weight: 700;
            font-size: 14px;
            line-height: 1.5;
            border-radius: 3px;
            /* identical to box height, or 157% */
            box-shadow:  2.5px 2.5px #EF7D00;
            letter-spacing: -0.02em;
            text-transform: uppercase;
    
            /* Black */
    
            color: #000000;
            @include d(md2){
                width: 100%;
                justify-content: center;
            }
        }
        svg{
            width: 24px;
            height: 24px;
            margin-left:1.5rem;
        }
    }

    .button-wrapper.only-mobile-btn{
        display: none;
        @include d(md2){
            display: block;
        }
    }

    .informacoes-wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.7rem;
        padding-top: 3rem;
        padding-left: 3rem;
        @include d(md2){
            padding-left: 0;
            grid-template-columns: 1fr;
            gap: 1.5rem;
        }
    }

    .section-title{
        border-bottom: 1px solid rgba(255, 255, 255, 0.20);
        margin-bottom: 1rem;
    }

    .selos{
        // padding-left: 3rem;
        margin-right: 0.7rem;


        li{
            font-size: 1rem;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: -0.32px;
            text-transform: uppercase;
            border-bottom: 1px solid rgba(255, 255, 255, 0.20);
            margin-bottom: 4px;
            padding-bottom: 4px;
        }
        li:last-child{
            border: none;
        }
    }

    .livros-wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 12px;

       
        .nome-livro{
            font-size: 14px;

            font-weight: 500;
            line-height: 1.3;
            letter-spacing: -0.28px; 
            text-transform: uppercase;

            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 2; 
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .img-wrapper{
            position: relative;
            padding-top: 112.94%;
            // border: 4px solid #D6DF21;
            &::after{
                content: '';
                position: absolute;
                inset: 0;
                border: 4px solid #D6DF21;
                
            }
            img{
                padding: 3px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                inset: 0;
            }
        }
    }



    // STEP 2 

    .header-expositor.step-2{
        padding-top: 1.5rem;
        // padding-bottom: 2rem;

        &::after{

            display: none;
        }
        .voltar-btn{
            margin-bottom: 2rem;
            color: #FFF;
            /* Headline / H4 - 12 Bold */
            font-size: 12px;    
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.24px;
            text-transform: uppercase;

            position: absolute;
            right: 0;
        }
        .info-nome{
            margin-bottom: 4px;
        }
        .confirmada-title{
            color:  #D6DF21;
            /* Headline / H2 - 16 Med */
            font-size: 1rem;

            font-weight: 500;
            line-height: 1.4;
            letter-spacing: -0.32px;
            text-transform: uppercase;
        }
    }

    .lista-programacao{
        
        padding: 3rem 0 0 3rem;
        padding-bottom: 0;
        @include d(md2){
            padding-left: 0;
        }

     

        ul{
            li{
                .section-title{
                    // border: none;
                    // display: grid;
                    // grid-template-columns: 0.7fr 1.3fr;
                    // align-items: center;
                    // gap: 4rem;
                    // margin-bottom: 1.5rem;
                    // @include d(md){
                    //     gap: 2rem;
                    // } 
                    // @include d(md2){
                    //     grid-template-columns: 1fr;
                    //     gap: 1rem;
                    // }

                    // .info-nome{
                    //     font-size: 1.125rem;
                    // }
                }

                .informacoes{
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    gap: 8px;
                    padding-bottom: 1.5rem;
                    @include d(md2){
                        grid-template-columns: 1fr;
                    }
                    .group{
                        border-radius: 1px;
                        padding: 8px;
                        border: 1px solid rgba(255, 255, 255, 0.10);

                        .label{
                            color: rgba(255, 255, 255, 0.50);
                        
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 18px;
                            letter-spacing: -0.28px;
                            text-transform: uppercase;
                        }
                        .info-nome{
                            font-size: 1.125rem;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}





