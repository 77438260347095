// AUTO SCROLL DOWN SIDEBAR 

@media (min-width:1024px){
    &{
        overflow: unset;
    
        main{
            overflow: unset;    
        }
        .side-bar{
            position: sticky;
            top: 120px;
        }
    }
}



#single-post{
    // background:red;
    margin-bottom: 10rem;

    @include d(md2){
        margin-bottom: 6rem;
    }


    .container{
        padding-top: 4rem;
        // padding-left: 8.5rem;;
    }
   

    .breadcrumbs{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;


        padding-left: 8.5rem;

        @include d(lg){
            grid-template-columns: 325px 1fr;
            padding-left: 4.5rem;
        }
        @include d(md2){
            padding-right: 10px;
            padding-left: 10px;
        }

       

        ul{
            display: flex;
            align-items: center;
            // gap: 1rem;
            .info-bread{
                margin-left: 5px; 
            }
            a{
                font-weight: 500;
                font-size: .875rem;
                line-height: 1.5;
                /* identical to box height, or 157% */

                letter-spacing: -0.02em;
                text-transform: uppercase;

                /* Neutral/60 */

                color: #59595C;
            }
        }

        .icon-mail{
            svg{
                width: 20px;
                height: 20px;
            }
        }

        .right{
            
            svg{
                width: 20px;
            height: 20px;
            }
        }


        .go-back{

            text-transform: uppercase;
            display: flex;
            align-items: center;

            font-weight: 500;
            font-size: .875rem;
            line-height: 1.5;
            /* identical to box height, or 157% */

        
            letter-spacing: -0.02em;
            text-transform: uppercase;

            /* Neutral/100 */

            color: #0F0F12;

            svg{
                transform: rotate(180deg);
                width: 24px;
                height: 24px;
                margin-right: 2px;
            }
        }
        @include d(md2){
            .go-back{
                display: none;
            }
        }
    }

    .single-title-wrapper{

        margin-bottom: 3.75rem;
             
        padding-left: 8.5rem;

        @include d(lg){
            grid-template-columns: 325px 1fr;
            padding-left: 4.5rem;
        }
        @include d(md2){
            padding-right: 10px;
            padding-left: 10px;
        }

        .cat {
            font-weight: 700;
            font-size: .875rem;
            line-height: 1.5;

            letter-spacing: -0.02em;
            text-transform: uppercase;
            
            /* White */
            
            color: #FFFFFF;
            max-width: max-content;
            padding: 5px 16px;
            border-radius: 16px;

            margin-bottom: 12px;
            &.rosa{
                background-color: $bienal-rosa;
            }

            &.roxo{}

        }

        .post-title{
            font-weight: 700;
            font-size: 2rem;
            line-height: 1.1;
         

            letter-spacing: -0.02em;
            text-transform: uppercase;

            /* Neutral/100 */

            color: #0C1428;
        }
        .bottom-info-blog{
            margin-top: 1rem;

            display: flex;
            align-items: center;
            justify-content: space-between;

            span{
                font-weight: 500;
                font-size: 1rem;
                line-height: 1.5;
                /* identical to box height, or 150% */

                letter-spacing: -0.02em;
                text-transform: uppercase;

                /* Neutral/60 */

                color: #59595C;
            }
        
            svg{
                width: 24px;
                height: 24px;
               
                filter: brightness(0) invert(1);
            }
        }
    }

    .main-img-wrapper{
        position: relative;
        margin-bottom: 4rem;

        &::before{
            content: '';
            width: 300px;
            height: 123px;
            background-color: #F3F3F5;
            position: absolute;
            left: 0;
            border-radius: 3px;
            bottom: -1.75rem;
            @include d(md2){
                display: none;
            }
        }


        .destaque-img-wrapper{
                position: relative;
                z-index: 2;
                padding-top: 1.5rem;
                padding-right: 8.5rem;
                padding-left: 8.5rem;
                border-radius: 3px;

                @include d(lg){
                    padding-right: 4.5rem;
                    padding-left: 4.5rem;
                }

                @include d(md2){
                    padding-right: 10px;
                    padding-left: 10px;
                }
            img{
                width: 100%;
                border-radius: 3px;
                object-fit: cover;
                max-height: 473px;
            }
        }
    }

    .degrade-bg{
            position: absolute;
            right: 0;
            top: 0;
            border-radius: 3px;
            max-width: 60%;
            @include d(md2){
                // max-width: 800px;
                left: 0;
                right: unset;
                max-width: 70%;
            }
        img{    
            // max-width: 1030px;
            border-radius: 3px;

            @include d(md2){            
                height: 140px;
                object-fit: cover;
            }
        }

    }


    .blog-content-wrapper{
        display: grid;
        align-items: flex-start;
        grid-template-columns: 376px 1fr;
        gap: 2rem;

        padding-left: 8.5rem;

        @include d(lg){
            grid-template-columns: 325px 1fr;
            padding-left: 4.5rem;
        }
        @include d(md){
            grid-template-columns: 1fr;
        }
        @include d(md2){
            padding-right: 10px;
            padding-left: 10px;
        }
       
    }


    .side-bar{
        border: 1px solid rgba(15, 15, 18, 0.1);
        border-radius: 3px;
        padding: 16px;

        .title-block{
            
            
            font-weight: 700;
            font-size: 16px;
            line-height: 1.5;
            /* identical to box height, or 150% */
 
            
            letter-spacing: -0.02em;
            text-transform: uppercase;
            margin-bottom: 1rem;

            /* Neutral/100 */

            color: #0F0F12;
           
        }
        .destaque-side{
            background-color: $bienal-rosa;
            border:  4px solid #FFCD29;
            padding: 12px;
            color: #fff;
            margin-bottom: 1rem;
           

            .title{
                font-weight: 500;
                font-size: 1.125rem;
                line-height: 1.4;
                /* identical to box height, or 144% */

               
                letter-spacing: -0.02em;
                text-transform: uppercase;

                /* White */

                color: #FFFFFF;
            }


            p{
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5;
                /* identical to box height, or 150% */

             
                letter-spacing: -0.02em;

                /* White */

                color: #FFFFFF; 
            }
        }


        .categorias{

            border-top: 1px solid rgba(15, 15, 18, 0.1);
            border-bottom: 1px solid rgba(15, 15, 18, 0.1);
            padding: 12px;
            padding-top: 12px;

            

            li{
                margin-bottom: 5px;
            }
            a{
                font-weight: 400;
                font-size: 16px;
                line-height: 1.5;
                /* identical to box height, or 150% */

                
                letter-spacing: -0.02em;
                padding: 5px 0;

                /* Neutral/70 */

                color: #36363B;
            }
        }
       

        .escrito-wrapper{
            padding: 12px ;

            .autor-wrapper{
                display: flex;
                align-items: center;
                gap: 8px;

                .autor-img-wrapper{
                    border-radius: 50%;
                    overflow: hidden;   
                    img{
                        width: 56px;
                        height: 56px;
                    }  
                }
                .autor-info{
                    .nome{
                        font-weight: 500;
                        font-size: 1rem;
                        line-height: 1.5;
                                          
                        letter-spacing: -0.02em;
                        text-transform: uppercase;

                        /* Neutral/100 */

                        color: #0F0F12;
                    }
                    p{
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 1.5;
                        /* identical to box height, or 150% */

                        
                        letter-spacing: -0.02em;
                       

                        /* Neutral/100 */

                        color: #36363B;
                    }
                }
            }
        }
          
        .compartilhar-wrapper{
            border-top: 1px solid rgba(15, 15, 18, 0.1);
            padding: 12px ;

            .sharer-icons{

                display: flex;
                align-items: center;
                gap: 8px;

                li{
                    background-color: $bienal-amarelo;
                    cursor: pointer;

                    svg{
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                    span{
                        margin-left: 6px;
                        
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: -0.02em;
                        text-transform: uppercase;

                        /* Black */

                        color: #000000;
                    }

                    a{
                        display: flex;
                        align-items: center;
                        padding: 8px;
                    }
                }
            }
        }
    }


    .blog-content{
        max-width: calc(784px + 200px);
        width: 100%;
        margin-left: auto;

        padding-right:  8.5rem;

        
        @include d(lg){
            padding-right: 4.5rem;
         
        }
        @include d(md){
            padding-right:0;
            grid-row: 1;
        }
       
       
     
    }
}


#veja-mais{

    margin-bottom: 4rem;

    @include d(md2){
        margin-bottom: 2rem;
    }
    .header-wrapper{

        background: url('../../src/assets/imagens/footer/footer-linear.webp');
        background-repeat: no-repeat;
        background-size: cover;
        position: relative; 
        margin-bottom: 4rem;

        padding: 1.5rem 2rem;
        h2{
            color: #fff;
            font-weight: 800;
            font-size: 1.5rem;
            line-height: 1.33;            
            letter-spacing: -0.02em;
            text-transform: uppercase;            
            color: #FFFFFF;
        }
        .ornamente-peixe{
            position: absolute;
            right: 10px;
            top: calc(50% - 20px);
            transform: translateY(-50%); 

            @include d(md2){
                max-width: 120px; 
                top: calc(50% - 10px); 
            }

            @include d(md2){
                display: none;
            }
        }
    }





    .bottom-info-blog{

        display: flex;
        align-items: center;
        justify-content: space-between;
    
        svg{
            width: 24px;
            height: 24px;
           
            filter: brightness(0) invert(1);
        }
    }
    
    .blog-card {
        border-radius: 3px;
        overflow: hidden;
        
    }
    .blog-card.blog-destaque{
        // display: flex;
    
    
        &.half{
            display: grid;
            grid-template-columns: 1fr 1fr;
    
            @include d(dt){
                grid-template-columns: 1fr;
                grid-template-rows: 60% 40%;
                display: flex;
                 flex-direction: column;
            }
        }
        &.half-bottom{
            display: grid;
            grid-template-rows: 60% 40%;
            @include d(dt){
                display: flex;
                flex-direction: column;
            }
        }
    
        .img-wrapper{
            @include d(dt){
                flex: 1;

                position: relative;
                padding-top: 75%;
                overflow: hidden;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;

                @include d(dt){
                    position: absolute;
                    inset: 0;
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                }
            }
        }
    
    
        
        
        .content-blog{
            background: #A3238E;
            padding: 2rem;
            color: #fff;
    
            display: flex;
            flex-direction: column;
            justify-content: space-between;
    
            @include d(dt){
                padding: 1rem;
                
            }
    
            h2{
                font-style: normal;
                font-weight: 600;
                font-size: 2rem;
                line-height: 1.1;
                /* or 106% */
                letter-spacing: -0.02em;
                text-transform: uppercase;
                flex: 1;
                margin-bottom: 1rem;
                
    
                display: flex;
                justify-content: flex-start;
                align-items: center;
                @include d(lg){
                    font-size: 1.5rem;
                }
                @include d(dt){
                    font-size: 1.2rem;
                }
            }
    
            .info-blog{
                font-weight: 700;
                font-size: 1rem;
                line-height: 1.5;
                /* identical to box height, or 150% */
    
                letter-spacing: -0.02em;
                text-transform: uppercase;
    
                color: rgba(255, 255, 255, 0.8);
                display: flex;
                flex-wrap: wrap;
                column-gap: 5px;
            }
        }
    }
    
    
    @media (min-width:769px){
        .swiper-midia {
            display: none;
          
        }
        
    }
    
    .swiper-midia{
        overflow: visible;
        .swiper-slide{
            height: auto;
    
            .blog-card{
                height: 100%;
                display: flex;
                flex-direction: column;
    
                .img-wrapper{
                    flex: 1;
                }
    
                .content-blog{
                    flex: unset;
                }
            }
        }
    
        .swiper-slide:nth-child(1) {
            .content-blog{
                background-color:  $bienal-rosa;
            }
        }
        .swiper-slide:nth-child(2) {
            .content-blog{
                background-color:  #369AA3;
            }
        }
        .swiper-slide:nth-child(3) { 
            .content-blog{
                background-color:  $bienal-laranja;
            }
        }
        .swiper-slide:nth-child(4) {
            .content-blog{
                background-color:  #369AA3;
            }
        }
    }
    
    .blog-grid-wrapper{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 27.8125rem 27.8125rem;
        gap: 2rem;
    
        @media( max-width: 768px){
            display: none;
        }
        @include d(lg){
            gap: 1rem;
            grid-template-rows: 25rem 25rem;
        }
    
        @include d(dt){
            grid-template-columns: 1fr 1fr;
            grid-template-rows: unset;
           
        }
        @include d(md2){
            grid-template-columns: 1fr;
        }
    
        .blog-card:nth-child(1){
            grid-template-columns: 1.6fr 1.4fr;
            grid-column: 1/ 4;
    
            @include d(dt){
                grid-template-columns: 1fr;
                grid-column: unset;
                // display: block;
            }
    
           
    
    
            .content-blog{
                background-color: $bienal-rosa;
            }
            img{
                max-height: 445px;
            }
        }
        .blog-card:nth-child(2){
            grid-column: 4 / 6;
    
            @include d(dt){
                grid-column: unset;
            }
    
            .content-blog{
                background-color:  #369AA3;
            }
        }
        .blog-card:nth-child(3){
            grid-row: 2;
            grid-column: 1 / 3;
    
            @include d(dt){
                grid-column: unset;
                grid-row: unset;
            }
            .content-blog{
                background-color:  $bienal-laranja;
            }
        }
        .blog-card:nth-child(4){
            grid-row: 2;
            grid-column: 3 / 5;
    
            @include d(dt){
                grid-column: unset;
                grid-row: unset;
            }
            .content-blog{
            background-color:  #369AA3;
             }
        }
    
        .todas-noticias{
            border: 1px solid #0F0F12;
            background: #FFFFFF;
            border-radius: 3px;
            padding: 1rem;
            display: grid;
            place-content: center;
            @include d(dt){
                grid-column:  span 2;
            }
            @include d(md2){
                grid-column: unset;
            }
            p{
                display: flex;
                align-items: center;
              
                font-weight: 700;
                font-size: 14px;
                line-height: 1.5;
                
                letter-spacing: -0.02em;
                text-transform: uppercase;
            }
            svg{
                width: 20px;
                margin-bottom: 5px;
                height: 21px;
            }
        }
    }
    
}