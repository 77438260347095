#footer-bienal{

    background-color: #EEEEF0;




    .sup-footer{
        background: url('../../src/assets/imagens/footer/footer-linear.webp');
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }


    .grid-sup-footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .logo-wrapper{
            padding: 100px 0;

            @include d(lg){
                max-width: 400px;
            }
            @include d(md2){
                padding: 32px 0;

            }
            img{
                @include d(md2){
                    max-width: 116px;

                }
            }
        }



        .go-top{
            background-color: $bienal-amarelo;
            display: flex;
            padding: 8px 9px;
            border-radius: 0 0 3px 3px;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
            p{

                font-weight: 700;
                font-size: 14px;
                line-height: 1.5;
                /* identical to box height, or 157% */

                letter-spacing: -0.02em;
            }
            svg{
                width: 20px;
                height: 20px;
                transform: rotate(-90deg);
                margin-right: .5rem;
            }
        }


        .right-col{
            position: relative;
            display: flex;
            align-items: center;
            align-self: stretch;

            @include d(md2){
                flex-direction: column-reverse;
            }
            .livro-wrapper{
                // position: absolute;
                align-self: flex-end;
                margin-bottom: -22px;

                @include d(md2){
                    max-width: 93px;
                    margin-bottom: -10px;
                    margin-right: auto;
                }
            }
        }
        .socials{
            @include d(md2){

            }
            ul{
                display: flex;
                justify-content: flex-end;
                gap: 9px;

                a{
                    padding: 2px;
                    display: block;
                }

                svg{
                    width: 3.5rem;
                    height: 3.5rem;
                    color: #D6DF21;
                    @include d(md2){
                        width: 2.5rem;
                        height: 2.5rem;

                    }
                }
            }
        }
    }



    .patrocinadores{

        padding: 73px 0;
        background-color: #fff;
        
        .row-1{
            display: flex;
            justify-content: center;
            margin-bottom: 4rem;
            column-gap: 4.5rem;
            gap: 48px;

            @include d(dt){
                display: grid;
                grid-template-columns: 1fr;
            }
            @include d(md2){
                margin-bottom: 4rem;
            }

            .group-wrapper:nth-child(1){
                flex-shrink: 0;
                align-self: flex-end;
                .img-wrapper{
                    // padding-bottom: 1rem;
                }
                img{
                    margin: 0 auto;
                }
            }

            .img-wrapper img{
                // transform: scale(0.93);
            }
        }

        .row-nova{
            margin-bottom: 4rem;
        }
        .row-2{
            // display: flex;
            // align-items: flex-start;
            // justify-content: space-between;
            display: grid;
            grid-template-columns: repeat(3,1fr);
            gap: 4.5rem;
            margin-bottom: 4rem;

            @include d(md){
                display: grid;
                grid-template-columns: 1fr;
            }
            .group-wrapper.apoio-group{
                // max-width: 385px;
                // width: 100%;
                flex: 0 0 20%;
                width: 100%;
                @include d(md){
                    margin: 0 auto;
                }
            }
            .group-wrapper.hotel-group{
                // max-width: 385px;
                // width: 100%;
                flex: 0 0 20%;
                width: 100%;
                @include d(md){
                    margin: 0 auto;
                }

            }
            .group-wrapper.realizacao-group{
                    flex: 1;
            }
        }


        .group-wrapper{

            p{
                font-weight: 400;
                font-size: 14px;
                line-height:1.5;


                letter-spacing: -0.02em;
                text-transform: uppercase;

                color: #0F0F12;
                text-align: center;

                border-bottom: 1px solid rgba(1, 1, 1, 0.30);;
                margin-bottom: 1.5rem;
            }

            .group, .group-1, .group-2{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 2rem;
                justify-content: center;
                @include d(lg){
                    gap: 1.5rem;
                }
            }

            .group-1{
                margin-bottom: 2rem;;
            }

            .group-1, .group-2 {
                @include d(md){
                    display: none;
                }
            }

            .group-mobile {
                @media(min-width:1024px){
                    display: none;
                }

                display: flex;
                flex-wrap: wrap;
                place-content: center;
                gap: 1.5rem;
            }
        }


        .apresenta-group{
            .group{
                padding: 0 24px;

                @include d(lg){
                    padding: 0 2rem;
                }

                gap: 3rem;
                justify-content: center;


            }
        }

        .group-wrapper.acao-social{
            .img-wrapper{
                img{
                    margin: 0 auto;
                }
            }
        }
        .patrocinadores-group{
            .group{
                justify-content: space-between;
            }
            @include d(dt){
                .group{
                    flex-wrap: wrap;
                    place-content: center;

                }
            }
        }
        .realizacao-group{
            @include d(sm){
                .group{
                    flex-wrap: wrap;
                }
            }
        }



        @media (max-width: 1440px){
            .realizacao-group img,
             .apoio-group img,
             .hotel-group img{
                 transform: scale(0.7);
                transform-origin: top;
            }
        }
        @media (max-width:1200px){
            .realizacao-group img, .apoio-group img,
            .hotel-group img{
                transform: scale(0.8);
                transform-origin: top;
            }
        }
    }

    .acao-social{
        p{
            padding: 0 20px;
        }
    }

    .bottom-footer{
        padding: 1.2rem 0;
        color: #fff;


        background: url('../../src/assets/imagens/footer/footer-linear.webp');
        background-repeat: no-repeat;
        background-size: cover;
        .container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            row-gap: 5px;
            column-gap: 1.5rem;
        }


        p{
            font-weight: 700;
            font-size: 1.125rem;
            line-height: 1.5;

            letter-spacing: -0.02em;
            text-transform: uppercase;

            color: #FFFFFF;
        }



            .ndt-wrapper{
                display: flex;
                align-items: center;
                gap: 10px;
                font-weight: 400;
                font-size: 12px;
                line-height: 1.5;

                letter-spacing: -0.02em;
                text-transform: uppercase;

                color: #FFFFFF;
                img{
                    padding-bottom: 7px;
                }
            }
    }
}

@media(max-width:1024px){
    #footer-bienal .logo-wrapper{
        max-width: 116px;
    }
}