.richTextEditorJs{
    color: #36363B;
    /* Style headings */
        h1, h2, h3 {
            margin-bottom: 1rem;
        }

        h2 {
        
            font-weight: 600;
            font-size: 2rem;
            line-height: 1.1;
            /* identical to box height, or 106% */

            letter-spacing: -0.02em;
            text-transform: uppercase;

            /* Neutral/100 */

            color: #0C1428;
        }
        
        h3 {
            font-size: 18px;
        }
        
        /* Style paragraphs and lists */
        p {
            margin-bottom: 1rem;

            font-weight: 400;
            font-size: 1.125rem;
            line-height: 1.44;
            /* or 144% */

            letter-spacing: -0.02em;

            /* Neutral/70 */

            color: #36363B;
        }
        
        ul, ol {
            margin-bottom: 10px;
            margin-left: 20px;


        }

    
        
        input[type="checkbox"]{
            -webkit-appearance: auto;
            appearance:  auto;

            
        }
        input[type="checkbox"] + label{
            margin-left: 5px;
        }
        
        /* Style links */
        a {
            color: #007bff;
            text-decoration: underline;
        }
        
        a:hover {
            color: #0056b3;
        }
        
        /* Style images */
        img {
            max-width: 100%;
            height: auto;
            margin-bottom: 10px;
        }
        
        /* Style blockquotes */
        blockquote {
            margin: 10px 0;
            padding: 10px;
            background-color: #f9f9f9;
            border-left: 4px solid #ccc;
        }
        
        /* Style code blocks */
        pre {
            margin: 10px 0;
            padding: 10px;
            background-color: #f9f9f9;
            border: 1px solid #ccc;
            white-space: pre-wrap;
            font-family: Consolas, Monaco, 'Courier New', Courier, monospace;
        }
        
        /* Style horizontal rules */
        hr {
            margin: 20px 0;
            border: none;
            border-top: 1px solid #ccc;
        }
        
        /* Style table */
        table {
            width: 100%;
            border-collapse: collapse;
        }
        
        th, td {
            padding: 8px;
            border-bottom: 1px solid #ccc;
        }
        
        /* Style form elements */
        input[type="text"],
        textarea {
            width: 100%;
            padding: 8px;
            border: 1px solid #ccc;
        }
        
        button {
            padding: 8px 12px;
            background-color: #007bff;
            color: #fff;
            border: none;
            cursor: pointer;
        }
        
        button:hover {
            background-color: #0056b3;
        }


        // CUSTOM ===================


        .list{
          
            li{
                list-style: auto;
                margin-left: 16px;
            }
        }
        li {
            list-style: disc;
            color: #36363B;
            font-size: 1.125rem;
            margin-bottom: .75rem;

        }

        .warning{
            position: relative;
            // margin-left: 30px;
            padding: 16px;
            padding-left: 30px;
            border: 1px solid #0f0f121a;
            border-radius: 3px;
            margin-bottom: 1rem;
           
            &::before{
                content: '';
                width: 18px;
                height: 18px;
                background-size: 18px 18px;
                position: absolute;
                top: 17px;
                left: 5px;
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 320 294' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath fill='%237B7E89' d='M160.5 97c12.426 0 22.5 10.074 22.5 22.5v28c0 12.426-10.074 22.5-22.5 22.5S138 159.926 138 147.5v-28c0-12.426 10.074-22.5 22.5-22.5zm0 83c14.636 0 26.5 11.864 26.5 26.5S175.136 233 160.5 233 134 221.136 134 206.5s11.864-26.5 26.5-26.5zm-.02-135c-6.102 0-14.05 8.427-23.842 25.28l-74.73 127.605c-12.713 21.444-17.806 35.025-15.28 40.742 2.527 5.717 8.519 9.175 17.974 10.373h197.255c5.932-1.214 10.051-4.671 12.357-10.373 2.307-5.702-1.812-16.903-12.357-33.603L184.555 70.281C174.608 53.427 166.583 45 160.48 45zm154.61 165.418c2.216 6.027 3.735 11.967 4.393 18.103.963 8.977.067 18.035-3.552 26.98-7.933 19.612-24.283 33.336-45.054 37.586l-4.464.913H61.763l-2.817-.357c-10.267-1.3-19.764-4.163-28.422-9.16-11.051-6.377-19.82-15.823-25.055-27.664-4.432-10.03-5.235-19.952-3.914-29.887.821-6.175 2.486-12.239 4.864-18.58 3.616-9.64 9.159-20.55 16.718-33.309L97.77 47.603c6.469-11.125 12.743-20.061 19.436-27.158 4.62-4.899 9.562-9.07 15.206-12.456C140.712 3.01 150.091 0 160.481 0c10.358 0 19.703 2.99 27.989 7.933 5.625 3.356 10.563 7.492 15.193 12.354 6.735 7.072 13.08 15.997 19.645 27.12l.142.24 76.986 134.194c6.553 10.46 11.425 19.799 14.654 28.577z'/%3E%3C/svg%3E");
            }
        }

        // .paragraph {
        //     font-size: 16px;
        //     line-height: 1.5;
        //     margin-bottom: 10px;
        //   }
        
        // .header {
        //     font-size: 24px;
        //     font-weight: bold;
        //     margin-bottom: 20px;
        // }
    
        // .table {
        //     border-collapse: collapse;
        //     width: 100%;
        //     margin-bottom: 20px;
        // }
    
        // .table td,
        // .table th {
        //     border: 1px solid black;
        //     padding: 8px;
        // }
    
        // .raw {
        //  margin-bottom: 20px;
        // }
    
        // .delimiter {
        //     border: none;
        //     border-top: 1px solid black;
        //     margin: 20px 0;
        // }
    
        // .code {
        //     background-color: #f4f4f4;
        //     padding: 10px;
        //     margin-bottom: 20px;
        //     font-family: monospace;
        // }
    
        // .quote {
        // border-left: 3px solid #ccc;
        // padding-left: 10px;
        // margin-bottom: 20px;
        // }
    
        // .list {
            
        //     // padding-left: 20px;
        //     list-style: inherit;
        //     li{
        //         list-style: auto;
            
        //     }
        // }


    
        // .embed {
        //     margin-bottom: 20px;
        // }
    
        // .image {
        //     max-width: 100%;
        //     margin-bottom: 20px;
        // }
}