.ingresos-fixed{

    // display: none !important;
// DESCONMENTAR ***************************************

    background: url('../../src/assets/imagens/footer/footer-linear.webp');
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    min-height: 82px;

    border-top: 1px solid #fff;

    p{
        color: #FFF;
        /* T5 - 22 Semi */
        font-size: 1.375rem;
      
        font-weight: 600;
        line-height: 1.4;
        letter-spacing: -0.44px;
        text-transform: uppercase;
        @include d(md){
            font-size: 1.2rem;
        }
        span{
            color: #D6DF21;
        }
    }

    .button-wrapper{
        transition: 0.2s ease-in;
        &:hover{
            transform: scale(1.02);
           
        }
        .button{
            background-color: $bienal-amarelo;
            width: 100%;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 13px 16px;
            font-weight: 700;
            font-size: 14px;
            line-height: 1.5;
            /* identical to box height, or 157% */

            letter-spacing: -0.02em;
            text-transform: uppercase;

            /* Black */

            color: #000000;

            box-shadow: 2.5px 2.5px #A11C64 ;
            @include d(md2){
                width: 100%;
                justify-content: center;
            }
        }
        svg{
            width: 24px;
            height: 24px;
            margin-left:1.5rem;
        }
    }
}

.body_home .ingresos-fixed{

    transform: translateY(190px);
}